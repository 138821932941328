import React, { PropsWithChildren, useState } from 'react';
import { Button, Col, Collapse, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Fa from 'react-icons/fa';

import styles from './PanelWindow.module.css';

import { useModal } from '../../providers/ModalManager';
import { useWindowManager } from '../../providers/WindowManager';
import { WindowPanelMap } from '../../types';
import { WindowState } from '../../windows';

export interface PanelWindowProps extends PropsWithChildren<any> {
    title: string;
    panelType: keyof WindowPanelMap;
    windowId: string;
    windowState: WindowState;
    collapsed?: boolean;
    onSave?: () => Promise<void> | void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    // Hook before closing window. If true is returned, then window is closed, otherwise not.
    onClose?: () => boolean;
}

export default function PanelWindow(props: PanelWindowProps) {
    const [isCollapsed, collapse] = useState(!!props.collapsed);
    const { t } = useTranslation();
    const { removeWindow } = useWindowManager();
    const { addModal } = useModal();

    const doRemoveWindow = () => removeWindow(props.panelType, props.windowId);
    // Remove window after modal confirmation if window has unsaved changes
    const onRemoveWindow = () => {
        // If onClose hook returned false, prevent window from closing
        if (props.onClose && !props.onClose()) {
            return;
        }

        if (props.windowState !== WindowState.IDLE) {
            addModal({
                title: t('modal.removeWindow.title'),
                body: t('modal.removeWindow.body'),
                confirmText: t('modal.removeWindow.confirm'),
                onConfirm: doRemoveWindow,
                onCancel: true,
                confirmVariant: 'danger',
            });
        } else {
            doRemoveWindow();
        }
    };

    const reduceSaveButtonClassFromState = (): string => {
        switch (props.windowState) {
            case WindowState.DIRTY:
                return styles.vinkaSaveButtonDirty;
            case WindowState.ERROR:
                return styles.vinkaSaveButtonError;
            default:
                return '';
        }
    };

    return (
        // Row so that array panelWindows stack vertically
        <Row
            className={styles.vinkaPanelWindow}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            <Col>
                {/* Separate rows for title bar and content, so that content appears below*/}
                <Row>
                    {/* Separate Columns for title and window buttons, so that window buttons take minimal
                    space and always appear in the same place*/}
                    <Col className={'p-0'}>
                        <p className={styles.vinkaPanelWindowTitle}>{props.title}</p>
                    </Col>
                    <Col className={['col-auto', 'p-0'].join(' ')}>
                        {props.onSave && (
                            <Button
                                className={[
                                    styles.vinkaWindowButton,
                                    reduceSaveButtonClassFromState(),
                                ].join(' ')}
                                onClick={() => props.onSave!()}
                            >
                                {props.windowState === WindowState.SAVING ? (
                                    <Fa.FaSpinner className={'vinkaSpinner'} />
                                ) : (
                                    <Fa.FaSave />
                                )}
                            </Button>
                        )}
                        <Button
                            onClick={() => collapse(!isCollapsed)}
                            className={[styles.vinkaWindowButton].join(' ')}
                            aria-controls="panel-window"
                            aria-expanded={!isCollapsed}
                        >
                            {isCollapsed ? <Fa.FaPlus /> : <Fa.FaMinus />}
                        </Button>
                        <Button
                            className={[
                                styles.vinkaWindowButton,
                                styles.vinkaWindowCloseButton,
                            ].join(' ')}
                            onClick={() => onRemoveWindow()}
                        >
                            {/* This icon is rotated in css so it looks like an "X". */}
                            <Fa.FaPlus />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    {/* Collapse causes error: Warning: findDOMNode is deprecated in StrictMode*/}
                    <Collapse in={!isCollapsed}>
                        <div id="panel-window" style={{ width: '100%' }}>
                            {props.children}
                        </div>
                    </Collapse>
                </Row>
            </Col>
        </Row>
    );
}
