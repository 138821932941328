import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { useAuth0 } from '@auth0/auth0-react';

import styles from './Layout.module.css';

import WindowContainer from '../../consumers/WindowContainer';
import Map from '../Map/Map';
import NavBar from '../NavBar/NavBar';
import VinkaModal from '../VinkaModal/VinkaModal';
import VinkaNotifications from '../VinkaNotifications/VinkaNotifications';

function Layout(): JSX.Element {
    const { isAuthenticated } = useAuth0();

    return (
        <div>
            <NavBar />
            {isAuthenticated && (
                <Container fluid className={styles.vinkaSuperContainer}>
                    <VinkaNotifications />
                    <Row className={styles.vinkaPanelsContainer}>
                        {/* Panel for "Detail" windows */}
                        <Col className={[styles.vinkaPanel, 'col-md-5'].join(' ')}>
                            <WindowContainer panel={'details'} scrollIntoView />
                        </Col>
                        <Col className={[styles.vinkaPanel, 'col-md-4'].join(' ')}>
                            <WindowContainer panel={'utils'} />
                        </Col>
                        {/* Panel for "List" windows */}
                        <Col className={[styles.vinkaPanel, 'col-md-3'].join(' ')}>
                            <WindowContainer panel={'lists'} />
                        </Col>
                    </Row>
                </Container>
            )}

            <VinkaModal />
            <Map />
        </div>
    );
}

export default Layout;
