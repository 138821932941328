import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Fa from 'react-icons/fa';

import PanelWindow from '../../components/PanelWindow/PanelWindow';
import { useRootStore } from '../../providers/RootStoreProvider';
import { useWindowManager } from '../../providers/WindowManager';
import { ManagedDetailWindowProps, Polygon, UIArea } from '../../types';
import { useValidation, validatePolygon, ValidationErrorData } from '../../utils/validation';
import { useErrorHandler, useSaveHandler, WindowState } from '../index';

function AreaDetailWindow(props: ManagedDetailWindowProps) {
    const { t } = useTranslation();
    const { dataStore, mapStore, notificationStore } = useRootStore();
    const { removeWindow, updateWindowId } = useWindowManager();
    const { parseValidationError } = useValidation();
    const { commonErrorHandler } = useErrorHandler({
        entityType: 'area',
        entityId: props.entityId,
    });

    const [windowState, setWindowState] = useState<WindowState>(WindowState.INIT);
    const [isEditingMap, setIsEditingMap] = useState(false);

    // Domain object state
    const [areaId, setStopId] = useState(props.entityId);
    const [name, setName] = useState<string>(
        props.isNew ? t('title.area_new') : t('title.area', { name: props.entityId })
    );
    const [description, setDescription] = useState('');
    const [polygon, setPolygon] = useState<undefined | Polygon>(undefined);
    const [polygonString, setPolygonString] = useState('');
    const [polygonIsValid, setPolygonIsValid] = useState(true);

    // We set window state to DIRTY from coordinate changes separately, buggy in effect dependencies.
    useEffect(() => {
        setWindowState(WindowState.DIRTY);
    }, [name, description]);

    const updatePolygonString = (rawPolygonVal: string) => {
        setPolygonString(rawPolygonVal);

        let parsedPolygon: Polygon | undefined = undefined;
        try {
            parsedPolygon = JSON.parse(rawPolygonVal);
        } catch (e) {
            if (!(e instanceof SyntaxError)) {
                console.error('Unexpected error when parsing JSON polygon', e);
            }
        }

        const polyIsValid = validatePolygon(parsedPolygon);
        setPolygonIsValid(polyIsValid);

        if (parsedPolygon && polyIsValid) {
            setPolygon(parsedPolygon);
            setWindowState(WindowState.DIRTY);
            // Reset polygon that was created when mouse hovered this window
            mapStore.clearAllByOwner(props.entityId);
            mapStore.addPolygon(props.entityId, parsedPolygon);
        }
    };

    const areaToState = (area: UIArea) => {
        setStopId(area.id);
        setName(area.name);
        setDescription(area.description || '');
        setPolygon(area.polygon);
        setPolygonString(JSON.stringify(area.polygon));
    };

    const stateToStop = (): UIArea | ValidationErrorData[] => {
        const errors: ValidationErrorData[] = [];
        const notSet = true;

        if (!areaId) {
            errors.push({ field: t('general.id'), notSet });
        }
        if (!name) {
            errors.push({ field: t('general.name'), notSet });
        }
        if (!polygon) {
            errors.push({ field: t('general.coordinates'), notSet });
        }

        if (errors.length > 0) {
            return errors;
        }

        return { id: areaId!, name, description, polygon: polygon! };
    };

    // Init & Cleanup
    useEffect(() => {
        (async () => {
            setWindowState(WindowState.UPDATING);
            try {
                if (!props.isNew) {
                    const area = await dataStore.getArea(props.entityId);
                    areaToState(area);
                    setWindowState(WindowState.IDLE);
                } else if (props.isNew) {
                    if (props.custom?.copyFromId) {
                        const area = await dataStore.getArea(props.custom.copyFromId);
                        areaToState(area);
                        setName(t('general.copyOf', { name: area.name }));
                    }

                    setWindowState(WindowState.DIRTY);
                }
            } catch (err) {
                commonErrorHandler(err);
                removeWindow('details', props.windowId);
            }
        })();

        return () => {
            mapStore.disableEditing(props.entityId);
            mapStore.clearAllByOwner(props.entityId);
        };
    }, []);

    const onSave = useSaveHandler(
        windowState,
        setWindowState,
        !props.isNew,
        async () => {
            const area = stateToStop();
            if (Array.isArray(area)) {
                throw parseValidationError(area);
            }
            if (!props.isNew) {
                await dataStore.updateArea(area);
            } else {
                const created = await dataStore.createArea(area);
                updateWindowId(props.windowId, created.id);
            }
        },
        async (err) => {
            commonErrorHandler(err, {
                status: {
                    409: () => {
                        if (err.response?.data?.message === 'Future routes have trips') {
                            notificationStore.addNotification({
                                level: 'error',
                                text: t(
                                    'notification.error.updateConflict.tripsMustBeUnscheduled',
                                    {
                                        tripIds: err.response?.data?.context.tripIds,
                                    }
                                ),
                                autoConfirm: false,
                            });
                            return true;
                        } else {
                            return false;
                        }
                    },
                },
            });
        }
    );

    const startEditing = () => {
        const editAllowed = mapStore.startEditingPolygon(
            props.entityId,
            polygon && polygon[0],
            (_polygon) => {
                if (_polygon) {
                    setPolygon(_polygon);
                    setPolygonString(JSON.stringify(_polygon));
                    setWindowState(WindowState.DIRTY);
                }
                setIsEditingMap(false);
            }
        );
        // Set isEditingMap to true only if no other component, or this one, is editing the map
        if (!isEditingMap && editAllowed) {
            setIsEditingMap(true);
        }
    };

    return (
        <PanelWindow
            title={name}
            panelType={'details'}
            windowId={props.windowId}
            windowState={windowState}
            onSave={onSave}
            onMouseEnter={() =>
                !isEditingMap && polygon && mapStore.addPolygon(props.entityId, polygon)
            }
            onMouseLeave={() => mapStore.clearAllByOwner(props.entityId)}
            onClose={() => {
                // Prevent closing window if user is editing map.
                if (isEditingMap) {
                    notificationStore.addNotification({
                        level: 'error',
                        text: t('notification.error.exitMapEditBeforeClose'),
                        autoConfirm: true,
                    });

                    return false;
                }

                return true;
            }}
        >
            <div>
                <Form.Group>
                    <Form.Label>{t('general.name')}</Form.Label>
                    <Form.Control value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('general.description')}</Form.Label>
                    <Form.Control
                        value={description || ''}
                        onChange={(e) => setDescription(e.target.value)}
                        as={'textarea'}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('title.area')}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            value={polygonString}
                            onChange={(e) => updatePolygonString(e.target.value)}
                            isInvalid={!polygonIsValid}
                        />
                        <InputGroup.Append>
                            <Button
                                variant={'success'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (mapStore.isOwnerFrozen(props.entityId, 'polygon')) {
                                        mapStore.unfreezeOwner(props.entityId, 'polygon');
                                    } else {
                                        mapStore.freezeOwner(props.entityId, 'polygon');
                                    }
                                }}
                            >
                                <Fa.FaMapPin
                                    style={{
                                        color: mapStore.isOwnerFrozen(props.entityId, 'polygon')
                                            ? '#FFFFFF'
                                            : '#b9b8b8',
                                    }}
                                />
                            </Button>
                            <Button onClick={() => startEditing()}>
                                <Fa.FaMapMarkerAlt />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
            </div>
        </PanelWindow>
    );
}

export default observer(AreaDetailWindow);
