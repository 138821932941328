import WindowManagerContext from '../contexts/WindowManagerContext';
import { allPanels } from '../utils';

/**
 * Subscribes to changes in window manager, and updates all window-related query parameters
 * @constructor
 */
export default function QueryParamsUpdater() {
    return (
        <WindowManagerContext.Consumer>
            {(state) => {
                const params = new URLSearchParams(window.location.search.slice(1));

                // Update query params when a panel's windows change.
                for (const panelType of allPanels) {
                    if (state[panelType].length) {
                        params.set(
                            panelType,
                            state[panelType].map((w) => w.props.windowId).join(',')
                        );
                    } else {
                        params.delete(panelType);
                    }
                }

                const paramString = params.toString();
                const url = paramString ? '?' + paramString : location.pathname;
                window.history.pushState(null, '', url);

                return <></>;
            }}
        </WindowManagerContext.Consumer>
    );
}
