/* eslint-disable camelcase */

export default {
    title: {
        service: 'Linja {{name}}',
        service_plural: 'Linjat',
        service_new: 'Uusi linja',
        stop: 'Pysäkki {{name}}',
        stop_plural: 'Pysäkit',
        stop_new: 'Uusi pysäkki',
        area: 'Alue {{name}}',
        area_plural: 'Alueet',
        area_new: 'Uusi alue',
        timetable: 'Aikataulu {{name}}',
        timetable_new: 'Uusi aikataulu',
    },
    error: {
        generic: 'Jokin meni pieleen',
    },
    button: {
        login: 'Kirjaudu sisään',
        logout: 'Kirjaudu ulos',
        createService: 'Luo linja',
        createTimetable: 'Luo aikataulu',
        createStop: 'Luo pysäkki',
        createArea: 'Luo alue',
        validate: 'Validoi',
    },
    general: {
        id: 'Tunniste',
        name: 'Nimi',
        description: 'Kuvaus',
        validity: 'Voimassaolo',
        filter: 'Suodata...',
        select: 'Valitse...',
        noResults: 'Ei hakutuloksia',
        vehicle: 'Auto',
        coordinates: 'Koordinaatit',
        startTyping: 'Aloita kirjoittamalla',
        loading: 'Ladataan...',
        address: 'Osoite',
        addressSearch: 'Osoitehaku',
        displayArchived: 'Näytä arkistoidut',
        copyOf: 'Kopio: {{name}}',
    },
    table: {
        timetable: {
            startTime: 'Lähtöaika',
            startLocation: 'Lähtöpaikka',
            indefinitely: 'Toistaiseksi',
        },
        service: {
            validIndefinitely: 'Voimassa toistaiseksi',
            validUntil: 'Voimassa {{date}}',
            validityEnded: 'Päättyi {{date}}',
        },
    },
    form: {
        timetable: {
            // Labels
            recurrence: 'Operoitavat viikonpäivät',
            weekday_short: {
                mon: 'Ma',
                tue: 'Ti',
                wed: 'Ke',
                thu: 'To',
                fri: 'Pe',
                sat: 'La',
                sun: 'Su',
            },
            omittedDates: 'Poikkeuspäivät',
            arriveLatest: 'Saapuminen',
            departEarliest: 'Lähtö',
            serviceId: 'Linjan tunniste',
            routePlan: 'Ajosuunnitelma',
            timetableItem: 'Aikataulun elementti',
            itemType: 'Elementin tyyppi',
            flexible: 'Joustava',
            validIndefinitely: 'Voimassa toistaiseksi',
            createRoute: 'Luo reitti (testikäyttöön)',
            validateTimetable: 'Validoi aikataulu',

            // Buttons
            addItem: 'Lisää elementti',
            editItem: 'Muokkaa elementtiä',
            clearItem: 'Tyhjennä elementti',
            addOmission: 'Lisää poikkeus',

            // Placeholders
            selectTypeFirst: 'Valitse tyyppi ensin',
            searchStop: 'Hae pysäkkiä...',
            searchArea: 'Hae aluetta...',
            selectDate: 'Valitse päivämäärä',
        },
        stop: {
            alias: 'Alias',
            street: 'Katu',
            streetNumber: 'Numero',
            city: 'Kunta',
        },
        mapContext: {
            closeMenu: 'Sulje valikko',
            addLocation: 'Lisää sijainti',
            cancelEditing: 'Peruuta alueen muokkaus',
            finishArea: 'Päätä alueen muokkaus',
            removePrevious: 'Poista viimeisin sijainti',
        },
    },
    modal: {
        defaultConfirm: 'Hyväksy',
        defaultCancel: 'Peruuta',
        logout: {
            title: 'Haluatko kirjautua ulos?',
            confirm: 'Kirjaudu ulos',
        },
        removeWindow: {
            title: 'Haluatko sulkea ikkunan?',
            body: 'Sinulla on tallentamattomia muutoksia',
            confirm: 'Sulje ikkuna',
        },
        archiveConfirm: {
            service: {
                title: 'Oletko varma?',
                body: 'Haluatko arkistoida linjan "{{name}}"?',
                confirm: 'Arkistoi linja',
            },
            timetable: {
                title: 'Oletko varma?',
                body: 'Haluatko arkistoida aikataulun "{{name}}"?',
                confirm: 'Arkistoi aikataulu',
            },
            stop: {
                title: 'Oletko varma?',
                body: 'Arkistoidaanko pysäkki "{{name}}"?',
                confirm: 'Arkistoi pysäkki',
            },
            area: {
                title: 'Oletko varma?',
                body: 'Arkistoidaanko alue "{{name}}"?',
                confirm: 'Arkistoi alue',
            },
        },
    },
    notification: {
        error: {
            // Common errors from requests
            connRefused: 'Palvelimeen ei saada yhteyttä.',
            badRequest: 'Pyyntö palvelimelle oli epämuodostunut: "{{message}}".',
            notFound: '{{entity}} {{id}} ei löytynyt.',
            forbidden: 'Tunnistautuminen epäonnistui. Yritä kirjautua sisään uudelleen.',
            serverInternal: 'Palvelimella tapahtui odottamaton virhe.',
            eventTimeout: 'Pyyntö katkaistiin pitkän keston vuoksi',

            // Catch-all error
            unexpected: 'Odottamaton virhe tapahtui.',

            mapEditOccupied: 'Kartta on jo muokkaustilassa',
            exitMapEditBeforeClose: 'Poistu kartan muokkaustilasta ennen ikkunan sulkemista',
            routeCreateFailed: 'Reitin luonti epäonnistui: {{message}}',
            archiveConflict: {
                areaUsedByTimetable: 'Aluetta käytetään aktiivisissa aikatauluissa',
                stopUsedByTimetable: 'Pysäkkiä käytetään aktiivisissa aikatauluissa',
                serviceHasActiveTimetables: 'Linjassa on aktiivisia aikatauluja',
                timetableIsActive: 'Aikataulu on vielä voimassa',
            },
            updateConflict: {
                tripsMustBeUnscheduled:
                    'Päivitys epäonnistui. Seuraavat tilaukset tulisi ensin poistaa reitiltä: {{tripIds}}',
            },
            timetableValidation: {
                tooTight:
                    'Aikataulu on liian tiukka, kaikkia pysäkkivälejä ei pystyttäisi ajamaan ajallaan',
            },
        },
        warn: {
            cannotSave: 'Tallennus ei ole mahdollista nyt, odota hetki.',
            stopEditFailed:
                'Pysäkkiä ei voitu muokata, pysäkki on saatettu jo poistaa aikataululta.',
            stopOwnerFrozen: 'Ei voida muokata kiinnitettyä pysäkkiä',
            areaOwnerFrozen: 'Ei voida muokata kiinnitettyä aluetta',
            timetableValidation: {
                saveBefore: 'Aikataulu on tallennettava ennen validointia',
                noRoutes:
                    'Aikataulu validoitiin, mutta annetulle päivämäärävälille ei generoitunut yhtäkään reittiä',
            },
        },
        info: {
            mapEditEnabled: 'Kartta on muokkaustilassa',
            routeCreateSuccess: 'Reitti {{id}} luotu',
            timetableValidated: 'Aikataulu validoitu',
        },
    },
    validation: {
        errorInForm: 'Virhe lomakkeessa:\n {{errors}}',
        noInfo: 'Virhe sovelluksessa, ei lisätietoja',
        notSetError: 'Kentät {{fields}} puuttuvat.',
        unknown: 'Kentissä {{fields}} tuntematon virhe.',
        routePlanEmpty: 'Ajosuunnitelma on tyhjä.',
    },
    tooltip: {
        timetableValidation:
            'Validointi generoi tilapäisesti reitit annetulle aikavälille, ja varmistaa että ne ovat ajokelpoisia',
    },
};
