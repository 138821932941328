import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import styles from './VinkaModal.module.css';

import { useModal } from '../../providers/ModalManager';

export default function VinkaModal() {
    const { modal, removeModal } = useModal();
    const { t } = useTranslation();

    return modal ? (
        <div className={styles.vinkaModalOverlay}>
            <Modal.Dialog className={styles.vinkaModalContent}>
                <Modal.Header className={styles.vinkaModalSection}>
                    <Modal.Title>{modal.title}</Modal.Title>
                </Modal.Header>
                {modal.body && (
                    <Modal.Body className={styles.vinkaModalSection}>
                        <p>{modal.body}</p>
                    </Modal.Body>
                )}
                <Modal.Footer className={styles.vinkaModalSection}>
                    {modal.onCancel && (
                        <Button
                            variant="secondary"
                            onClick={() => {
                                if (typeof modal.onCancel === 'function') {
                                    modal.onCancel();
                                }
                                removeModal();
                            }}
                        >
                            {modal.cancelText ? modal.cancelText : t('modal.defaultCancel')}
                        </Button>
                    )}
                    <Button
                        variant={modal.confirmVariant || 'primary'}
                        onClick={() => {
                            if (typeof modal.onConfirm === 'function') {
                                modal.onConfirm();
                            }
                            removeModal();
                        }}
                    >
                        {modal.confirmText ? modal.confirmText : t('modal.defaultConfirm')}
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    ) : (
        <></>
    );
}
