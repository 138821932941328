import debounce from 'debounce-promise';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { NamedProps, OptionsType } from 'react-select';
import AsyncSelect from 'react-select/async';

import { useRootStore } from '../../providers/RootStoreProvider';
import { Address } from '../../types';
import { selectStyles } from '../../utils';

const Q_DEBOUNCE = 100;
const RE_STREET_NUMBER = /^\d[0-9/-]*\w?$/;

type ComponentParams = {
    value: Address | null;
    onChange: NamedProps<Address>['onChange'];
};

export const genFormattedAddress = (i: Address) =>
    [[i.alias, i.street, i.streetNumber].filter(Boolean).join(' '), i.city]
        .filter(Boolean)
        .join(', ');

const AddressInput = ({ value, onChange }: ComponentParams) => {
    const { t } = useTranslation();
    const { dataStore } = useRootStore();

    const loadOptions = async (input: string): Promise<Address[]> => {
        let query = input.trim();

        // if we already have an address with the street and city values,
        // and the input matches the street number pattern - extend it
        if (value && value.street && value.city && RE_STREET_NUMBER.test(query)) {
            query = `${value.street} ${query}, ${value.city}}`;
        }

        return dataStore.geocode(query);
    };

    const debouncedLoadOptions = debounce(loadOptions, Q_DEBOUNCE);

    const highlight = (i: Address) => (i ? genFormattedAddress(i) : '');

    const formatOptionLabel = (i: Address, { inputValue }: { inputValue: string }) => (
        <Highlighter
            searchWords={inputValue.split('%').filter(Boolean)}
            textToHighlight={highlight(i)}
        />
    );

    const setNoOptionsMessage = (): string => {
        return value ? highlight(value) : t('general.startTyping');
    };

    const isOptionSelected = (i: Address, selections: OptionsType<Address>): boolean =>
        selections.some((s) => s.lat === i.lat && s.lng === i.lng);

    return (
        <AsyncSelect
            isClearable
            // cacheOptions={true}
            blurInputOnSelect={false}
            placeholder={t('general.addressSearch')}
            classNamePrefix="react-select"
            value={value}
            loadOptions={debouncedLoadOptions}
            loadingMessage={() => t('general.loading')}
            formatOptionLabel={formatOptionLabel}
            noOptionsMessage={setNoOptionsMessage}
            isOptionSelected={isOptionSelected}
            styles={selectStyles as any}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            maxMenuHeight={200}
            onChange={onChange}
        />
    );
};

export default AddressInput;
