import { values } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button, FormCheck, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Fa from 'react-icons/fa';

import tableStyles from '../../components/VinkaTable/VinkaTable.module.css';

import PanelWindow from '../../components/PanelWindow/PanelWindow';
import VinkaTable from '../../components/VinkaTable/VinkaTable';
import { useModal } from '../../providers/ModalManager';
import { useRootStore } from '../../providers/RootStoreProvider';
import { useWindowManager } from '../../providers/WindowManager';
import {
    ManagedDetailWindowProps,
    MappedService,
    ScheduledStop,
    UIService,
    UITimetable,
} from '../../types';
import { useSaveHandler, useErrorHandler, WindowState } from '../index';

function ServiceDetailWindow(props: ManagedDetailWindowProps) {
    const { t } = useTranslation();
    const { dataStore, notificationStore } = useRootStore();
    const { addWindow, removeWindow, updateWindowId } = useWindowManager();
    const { addModal } = useModal();
    const { commonErrorHandler } = useErrorHandler({
        entityType: 'service',
        entityId: props.entityId,
    });

    const [windowState, setWindowState] = useState<WindowState>(WindowState.INIT);
    const [displayArchived, setDisplayArchived] = useState(false);

    // Entity state
    const [serviceId, setServiceId] = useState<string>(props.entityId);
    const [name, setName] = useState<string>(
        props.isNew ? t('title.service_new') : t('title.service', { name: props.entityId })
    );
    const [description, setDescription] = useState('');

    // Set windowState to DIRTY when entity state modified.
    // TODO This is buggy, for some reason windowState always ends up dirty when opening new window.
    // useEffect(() => setWindowState(WindowState.DIRTY), [name, description]);

    useEffect(() => {
        (async () => {
            try {
                setWindowState(WindowState.UPDATING);
                if (!serviceId) {
                    throw Error('Cannot create detail window for a service that has no id');
                } else if (!props.isNew) {
                    const service = await dataStore.getService(serviceId);
                    serviceToState(service);
                    setWindowState(WindowState.IDLE);
                } else if (props.isNew) {
                    setWindowState(WindowState.DIRTY);
                }
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error('Error initializing service detail', err);
                commonErrorHandler(err);
                removeWindow('details', props.windowId);
            }
        })();
    }, []);

    const serviceToState = (service: MappedService) => {
        setServiceId(service.id);
        setName(service.name);
        setDescription(service.description || '');
    };

    const stateToService = (): UIService => {
        // Timetables is passed as an empty array, because we do not edit timetables directly in this
        // component, we only list them from store. Even though this empty array is passed to the API,
        // this shouldn't overwrite any existing services, because in PUT /service/id timetables are
        // not changed.
        return { id: serviceId, name, description, timetables: [] };
    };

    const getFirstStop = (timetable: UITimetable): ScheduledStop<string> | undefined => {
        return (
            timetable.stops.find((stop) => stop.ordinal === 0) ||
            timetable.areas.find((area) => area.ordinal === 0)
        );
    };

    const formatValidity = (timetable: UITimetable): string =>
        timetable.validity.from.format('L') +
        '-' +
        (timetable.validity.to
            ? timetable.validity.to.format('L')
            : t('table.timetable.indefinitely'));
    const formatStartTime = (timetable: UITimetable): string =>
        getFirstStop(timetable)?.arriveLatest || 'N/A';
    const formatStartLocation = (timetable: UITimetable): string =>
        getFirstStop(timetable)?.location?.name || 'N/A';

    const onSave = useSaveHandler(
        windowState,
        setWindowState,
        !props.isNew,
        async () => {
            if (!props.isNew) {
                await dataStore.updateService(stateToService());
            } else {
                const newService = await dataStore.createService(stateToService());
                // This essentially recreates the component in place
                updateWindowId(props.entityId!, newService.id);
            }
        },
        async (err) => {
            commonErrorHandler(err);
        }
    );

    const onArchiveClick = (timetable: UITimetable) => {
        addModal({
            title: t('modal.archiveConfirm.timetable.title'),
            body: t('modal.archiveConfirm.timetable.body', {
                name: timetable.name,
            }),
            confirmText: t('modal.archiveConfirm.timetable.confirm'),
            onConfirm: () => archiveTimetable(timetable),
            onCancel: true,
            confirmVariant: 'danger',
        });
    };

    const archiveTimetable = async (timetable: UITimetable) => {
        try {
            await dataStore.deleteTimetable(serviceId, timetable.id);
        } catch (e) {
            commonErrorHandler(e, {
                status: {
                    409: () => {
                        if (e.response?.data?.message === 'Timetable is active') {
                            notificationStore.addNotification({
                                level: 'error',
                                text: t('notification.error.archiveConflict.timetableIsActive'),
                                autoConfirm: true,
                            });
                            return true;
                        } else {
                            return false;
                        }
                    },
                },
                entityId: timetable.id,
            });
        }
    };

    return (
        <PanelWindow
            title={name}
            panelType={'details'}
            windowId={props.windowId}
            windowState={windowState}
            onSave={() => onSave()}
        >
            <div>
                <FormGroup>
                    <FormLabel>{t('general.name')}</FormLabel>
                    <FormControl value={name} onChange={(e) => setName(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <FormLabel>{t('general.description')}</FormLabel>
                    <FormControl
                        value={description}
                        as={'textarea'}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </FormGroup>
                <VinkaTable>
                    <thead>
                        <tr>
                            <td style={{ border: 'none' }}>
                                <FormCheck
                                    inline
                                    type={'checkbox'}
                                    label={t('general.displayArchived')}
                                    checked={displayArchived}
                                    onChange={() => setDisplayArchived(!displayArchived)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('general.name')}</th>
                            <th>{t('table.timetable.startTime')}</th>
                            <th>{t('table.timetable.startLocation')}</th>
                            <th>{t('general.vehicle')}</th>
                            <th>{t('general.validity')}</th>
                            <th>{/* Column for action buttons */}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(dataStore.services.get(serviceId)
                            ? values(dataStore.services.get(serviceId)!.timetables)
                            : []
                        )
                            .filter((timetable) => displayArchived || !timetable.deletedAt)
                            .map((timetable) => {
                                return (
                                    <tr
                                        key={`service${serviceId}timetable${timetable.id}`}
                                        className={tableStyles.tableLink}
                                        onClick={() =>
                                            addWindow({
                                                panelType: 'details',
                                                entityType: 'timetable',
                                                entityId: '' + timetable.id,
                                            })
                                        }
                                    >
                                        <td>{timetable.name}</td>
                                        <td>{formatStartTime(timetable)}</td>
                                        <td>{formatStartLocation(timetable)}</td>
                                        <td>{timetable.vehicle}</td>
                                        <td>{formatValidity(timetable)}</td>
                                        <td>
                                            <Fa.FaCopy
                                                className={tableStyles.actionIcon}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    addWindow({
                                                        entityType: 'timetable',
                                                        panelType: 'details',
                                                        isNew: true,
                                                        custom: {
                                                            copyFromId: timetable.id,
                                                        },
                                                    });
                                                }}
                                            />
                                            <Fa.FaTrashAlt
                                                className={[
                                                    tableStyles.actionIcon,
                                                    tableStyles.deleteActionIcon,
                                                ].join(' ')}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onArchiveClick(timetable);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </VinkaTable>
                <Button
                    style={{ marginTop: '1em' }}
                    disabled={props.isNew}
                    onClick={() =>
                        addWindow({
                            entityType: 'timetable',
                            panelType: 'details',
                            isNew: true,
                            custom: {
                                serviceId,
                            },
                        })
                    }
                >
                    {t('button.createTimetable')}
                </Button>
            </div>
        </PanelWindow>
    );
}

export default observer(ServiceDetailWindow);
