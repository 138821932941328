import { PropsWithChildren } from 'react';

import WindowManagerContext from '../contexts/WindowManagerContext';
import { ManagedWindowConfig } from '../types';

/**
 * Adds a window with window manager on click.
 * @param props
 * @constructor
 */
export default function WindowLink(props: PropsWithChildren<ManagedWindowConfig>) {
    return (
        <WindowManagerContext.Consumer>
            {({ addWindow }) => <div onClick={() => addWindow(props)}>{props.children}</div>}
        </WindowManagerContext.Consumer>
    );
}
