import { useEffect, useRef } from 'react';

import { useWindowManager } from '../providers/WindowManager';
import { WindowPanelMap } from '../types';

export interface WindowContainerProps {
    panel: keyof WindowPanelMap;
    scrollIntoView?: true;
}

/**
 * Container for a panel of windows from window manager.
 * @param props.panel panel name
 * @constructor
 */
export default function WindowContainer({ panel, scrollIntoView }: WindowContainerProps) {
    const wm = useWindowManager();
    const containerRef = useRef<any>(null);

    if (scrollIntoView) {
        // https://stackoverflow.com/a/67611672/13167212
        useEffect(() => {
            if (containerRef.current) {
                containerRef.current.scrollIntoView();
            }
        }, [wm[panel]]);
    }

    return <div ref={containerRef}>{wm[panel]}</div>;
}
