import { LatLng, LeafletMouseEvent } from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import { values } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    MapContainer,
    Marker,
    Polygon as ReactLeafletPolygon,
    Polyline,
    TileLayer,
    useMapEvents,
} from 'react-leaflet';

import styles from './Map.module.css';

import config from '../../infra/config';
import { useRootStore } from '../../providers/RootStoreProvider';
import { BlueMarker, GreenMarker, RedMarker, YellowMarker } from '../MapMarkerIcons/MapMarkerIcons';

interface MapContextMenuProps {
    event: LeafletMouseEvent;
    onAdd: (latLng: LatLng) => void;
    onCancel: () => void;
    onExit: () => void;
    // Callbacks specific to editing polygons
    finishArea?: () => void;
    removePrevious?: () => void;
}

function MapContextMenu(props: MapContextMenuProps) {
    const { t } = useTranslation();
    const { event, onAdd, onCancel, onExit, finishArea, removePrevious } = props;

    return (
        <div
            className={styles.contextMenuContainer}
            style={{
                left: event.originalEvent.x,
                top: event.originalEvent.y,
            }}
        >
            <Dropdown.Item className={styles.contextMenuItem} disabled={true}>
                {`${event.latlng.lat.toFixed(5)}, ${event.latlng.lng.toFixed(5)}`}
            </Dropdown.Item>
            <Dropdown.Divider className={styles.contextMenuDivider} />
            <Dropdown.Item
                className={styles.contextMenuItem}
                onClick={(e) => {
                    e.stopPropagation();
                    onAdd(event.latlng);
                }}
            >
                {t('form.mapContext.addLocation')}
            </Dropdown.Item>
            {removePrevious && (
                <Dropdown.Item
                    className={styles.contextMenuItem}
                    onClick={(e) => {
                        e.stopPropagation();
                        removePrevious();
                    }}
                >
                    {t('form.mapContext.removePrevious')}
                </Dropdown.Item>
            )}
            <Dropdown.Item
                className={styles.contextMenuItem}
                onClick={(e) => {
                    e.stopPropagation();
                    onCancel();
                }}
            >
                {t('form.mapContext.closeMenu')}
            </Dropdown.Item>
            <Dropdown.Divider className={styles.contextMenuDivider} />
            {finishArea && (
                <Dropdown.Item
                    className={styles.contextMenuItem}
                    onClick={(e) => {
                        e.stopPropagation();
                        finishArea();
                    }}
                >
                    {t('form.mapContext.finishArea')}
                </Dropdown.Item>
            )}
            <Dropdown.Item
                className={styles.contextMenuItem}
                onClick={(e) => {
                    e.stopPropagation();
                    onExit();
                }}
            >
                {t('form.mapContext.cancelEditing')}
            </Dropdown.Item>
        </div>
    );
}

interface MapControllerProps {
    editablePolygon: LatLng[];
    setEditablePolygon: Dispatch<SetStateAction<LatLng[]>>;
    // The index of the polygon where new points are pushed
    polygonPushIndex: number | null;
    setPolygonPushIndex: Dispatch<SetStateAction<number | null>>;
}

const MapController = observer(function MapController(props: MapControllerProps) {
    const [contextMenu, setContextMenu] = useState<MapContextMenuProps | null>(null);
    const { mapStore } = useRootStore();

    // useMapEvents hook works on descendant of MapContainer
    useMapEvents({
        // Opens when right-clicking map
        contextmenu(e) {
            if (mapStore.editor.state !== 'inactive' && mapStore.editor.ownerId) {
                // clear markers to avoid confusion, as we set a new marker at the position where the user clicked
                mapStore.clearAllByOwner(mapStore.editor.ownerId);
                if (mapStore.editor.state === 'marker') {
                    mapStore.addMarker(mapStore.editor.ownerId, e.latlng.lng, e.latlng.lat);
                }

                const cleanup = () => {
                    // In practice, ownerId should always be defined at this point
                    if (mapStore.editor.ownerId) {
                        mapStore.clearAllByOwner(mapStore.editor.ownerId);
                    }
                    setContextMenu(null);
                };

                const contextProps: MapContextMenuProps = {
                    event: e,
                    onAdd: (latLng) => {
                        cleanup();
                        if (mapStore.editor.state === 'marker' && mapStore.editor.callback) {
                            mapStore.editor.callback(latLng);
                        } else if (mapStore.editor.state === 'polygon') {
                            if (props.polygonPushIndex === null) {
                                props.setEditablePolygon([...props.editablePolygon, latLng]);
                            } else {
                                // Push latlng to next point after polygonPushIndex
                                props.setEditablePolygon(
                                    props.editablePolygon
                                        .slice(0, props.polygonPushIndex + 1)
                                        .concat(latLng)
                                        .concat(
                                            props.editablePolygon.slice(props.polygonPushIndex + 1)
                                        )
                                );
                                props.setPolygonPushIndex(props.polygonPushIndex + 1);
                            }
                        }
                    },
                    onCancel: () => {
                        cleanup();
                    },
                    onExit: () => {
                        cleanup();
                        if (mapStore.editor.callback) {
                            mapStore.editor.callback(null);
                        }
                        props.setEditablePolygon([]);
                        props.setPolygonPushIndex(null);
                    },
                };

                if (mapStore.editor.state === 'polygon') {
                    contextProps.finishArea = () => {
                        cleanup();
                        if (mapStore.editor.callback) {
                            mapStore.editor.callback(props.editablePolygon);
                            props.setEditablePolygon([]);
                            props.setPolygonPushIndex(null);
                        }
                    };
                    contextProps.removePrevious = () => {
                        cleanup();
                        // If no push index set, points are appended to the end of end of the polygon,
                        // therefore the last point of the polygon should be removed
                        if (props.polygonPushIndex === null) {
                            props.setEditablePolygon(props.editablePolygon.slice(0, -1));
                        }
                        // Polygon push index set, we have an effective index for the "previous" polygon point,
                        // remove the element at that index.
                        else {
                            props.setEditablePolygon(
                                props.editablePolygon
                                    .slice(0, props.polygonPushIndex)
                                    .concat(props.editablePolygon.slice(props.polygonPushIndex + 1))
                            );
                            // When we've deleted a point shift back the index where new points are pushed.
                            if (props.polygonPushIndex !== 0) {
                                props.setPolygonPushIndex(props.polygonPushIndex - 1);
                            }
                        }
                    };
                }

                setContextMenu(contextProps);
            }
        },
    });

    if (contextMenu) {
        return <MapContextMenu {...contextMenu} />;
    }

    return null;
});

export function Map(): JSX.Element {
    const apiKey = 'NckITLLF4t5rrbI5URSnC7BnYlbWA0mL';
    const { mapStore } = useRootStore();
    const [editablePolygon, setEditablePolygon] = useState<LatLng[]>([]);
    // Variable for where the next point in a polygon will be pushed
    const [polygonPushIndex, setPolygonPushIndex] = useState<number | null>(null);

    useEffect(() => {
        if (mapStore.editor.initialPolygon !== null) {
            setEditablePolygon(
                mapStore.editor.initialPolygon.map((coords) => new LatLng(coords[1], coords[0]))
            );
        }
    }, [mapStore.editor.initialPolygon]);

    // Gets marker icon for editable polygon. First and last markers are highlighted.
    const getMarkerIcon = (itemIndex: number, itemsLength: number) => {
        if (itemIndex === 0 && polygonPushIndex === 0) {
            return YellowMarker;
        } else if (itemIndex === 0) {
            return GreenMarker;
        } else if (
            // If we have set the polygon index we want to push points after, highlight the marker at the index
            (polygonPushIndex !== null && itemIndex === polygonPushIndex) ||
            // If we have no polygonPushIndex, highlight the last marker
            (polygonPushIndex === null && itemIndex === itemsLength - 1)
        ) {
            return RedMarker;
        } else {
            return BlueMarker;
        }
    };

    return (
        <MapContainer
            zoomControl={false}
            maxZoom={18}
            zoom={13}
            className={styles.backgroundMap}
            center={config.app.mapCenter}
        >
            <MapController
                editablePolygon={editablePolygon}
                setEditablePolygon={setEditablePolygon}
                polygonPushIndex={polygonPushIndex}
                setPolygonPushIndex={setPolygonPushIndex}
            />
            <TileLayer
                url={`https://{s}.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=${apiKey}`}
            />
            {values(mapStore.polygons).map((polygonArray, index1) =>
                polygonArray.map((polygon, index2) => (
                    <ReactLeafletPolygon positions={polygon} key={`map-poly-${index1}-${index2}`} />
                ))
            )}
            {values(mapStore.markers).map((latLngArray, index1) =>
                latLngArray.map((latlng, index2) => (
                    <Marker position={latlng} key={`map-marker-${index1}-${index2}`} />
                ))
            )}
            {mapStore.highlightedPolygon && (
                <ReactLeafletPolygon
                    positions={mapStore.highlightedPolygon}
                    pathOptions={{ color: 'green' }}
                />
            )}
            {mapStore.highlightedMarker && (
                <Marker position={mapStore.highlightedMarker} icon={GreenMarker} />
            )}
            {editablePolygon.map((latLng, index, points) => (
                <div key={`editablepoly-point-${index}`}>
                    <Marker
                        icon={getMarkerIcon(index, points.length)}
                        position={latLng}
                        draggable={true}
                        eventHandlers={{
                            drag: (dragData) => {
                                // Change polygon, so that the position of the dragged polygon is updated.
                                setEditablePolygon([
                                    ...editablePolygon.slice(0, index),
                                    dragData.target._latlng,
                                    ...editablePolygon.slice(index + 1, editablePolygon.length),
                                ]);
                            },
                            dblclick: () => {
                                setPolygonPushIndex(index);
                            },
                        }}
                    />
                    <Polyline
                        positions={[
                            latLng,
                            // Connect with next point, or the first.
                            index + 1 < points.length ? points[index + 1] : points[0],
                        ]}
                    />
                </div>
            ))}
        </MapContainer>
    );
}

export default observer(Map);
