import { values } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import { useRootStore } from '../../providers/RootStoreProvider';
import { StopType, TypedScheduledStop, UIArea, UIStop } from '../../types';
import { selectStyles } from '../../utils';
import { useValidation, ValidationErrorData } from '../../utils/validation';

export interface TimetableStopFormProps {
    onChange: (oldStop: TypedScheduledStop | null, newStop: TypedScheduledStop) => void;
    stop: TypedScheduledStop | null;
}

// Helper interface for react-select option for stopType
interface StopTypeOption {
    value: string;
    label: string;
}

// Helper interface for react-select option for selectedStop
interface StopOption {
    value: string;
    label: string;
    data: UIStop | UIArea;
}

function TimetableStopForm(props: TimetableStopFormProps) {
    const { dataStore, notificationStore } = useRootStore();
    const { t } = useTranslation();
    const { parseValidationError } = useValidation();

    // Refs used for clearing inputs and setting initial values
    const refs = {
        selectStopType: useRef<Select>(null),
        selectStop: useRef<any>(null),
        inputDepart: useRef<HTMLInputElement>(null),
        inputArrive: useRef<HTMLInputElement>(null),
    };

    // Entity state
    const [stopType, setStopType] = useState<StopType | undefined>(undefined);
    const [selectedStop, setSelectedStop] = useState<UIStop | UIArea | undefined>(undefined);
    const [arriveLatest, setArriveLatest] = useState<string | undefined>(undefined);
    const [departEarliest, setDepartEarliest] = useState<string | undefined>(undefined);
    const [isFlexible, setIsFlexible] = useState<boolean | undefined>(undefined);
    const [ordinal, setOrdinal] = useState(-1);

    // Stop provided by parent to component, for modification. If null, we are creating a new stop.
    const [oldStop, setOldStop] = useState<TypedScheduledStop | null>(null);

    // This is used to determine when to use cache when searching for stops/areas
    const [previousStopType, setPreviousStopType] = useState<StopType | undefined>(undefined);

    // State specifically for input/select components
    const [stopTypeOption, setStopTypeOption] = useState<StopTypeOption | null>(null);
    const [selectedStopOption, setSelectedStopOption] = useState<StopOption | null>(null);

    useEffect(() => {
        setOldStop(props.stop);

        if (props.stop) {
            // Update state
            setStopType(props.stop.type);
            setSelectedStop(props.stop.location);
            setArriveLatest(props.stop.arriveLatest);
            setDepartEarliest(props.stop.departEarliest);
            setIsFlexible(props.stop.isFlexible);
            setOrdinal(props.stop.ordinal);

            // Update what the inputs show
            setStopTypeOption(stopTypeOptions.find((option) => option.value === props.stop!.type)!);
            setSelectedStopOption({
                value: `${props.stop.type}-${props.stop.location.id}`,
                label: props.stop.location.name,
                data: props.stop.location,
            });
            // Update via ref, as directly setting value for inputs breaks editability
            if (refs.inputArrive.current) {
                refs.inputArrive.current.value = props.stop.arriveLatest;
            }
            if (refs.inputDepart.current) {
                refs.inputDepart.current.value = props.stop.departEarliest;
            }
            // isFlexible checkbox is updated via entity state, no further action required.
        }
    }, [props.stop]);

    // Fixed options for selecting stop type
    const stopTypeOptions: StopTypeOption[] = [
        { value: 'stop', label: t('title.stop') },
        { value: 'area', label: t('title.area') },
    ];

    // Async fetched options for selecting stop/area
    // eslint-disable-next-line @typescript-eslint/ban-types
    const loadStopOptions: typeof AsyncSelect.defaultProps.loadOptions = async () => {
        // Effectively makes dataStore fetch values every time stopType has changed.
        const useCache = stopType === previousStopType;
        setPreviousStopType(stopType);

        if (stopType === 'stop') {
            const stops = values(await dataStore.getStops(useCache));
            return stops
                .filter((stop) => !stop.deletedAt)
                .map((stop) => ({
                    value: `stop-${stop.id}`,
                    label: stop.name,
                    data: stop,
                }));
        } else if (stopType === 'area') {
            const areas = values(await dataStore.getAreas(useCache));
            return areas
                .filter((area) => !area.deletedAt)
                .map((area) => ({
                    value: `area-${area.id}`,
                    label: area.name,
                    data: area,
                }));
        } else {
            return [];
        }
    };

    const getStopSearchPlaceHolder = (): string => {
        switch (stopType) {
            case undefined:
                return t('form.timetable.selectTypeFirst');
            case 'stop':
                return t('form.timetable.searchStop');
            case 'area':
                return t('form.timetable.searchArea');
            default:
                throw Error('Unrecognized stop type: ' + stopType);
        }
    };

    const clearForm = () => {
        if (refs.selectStopType.current) {
            refs.selectStopType.current.select.clearValue();
        }
        if (refs.selectStop.current) {
            refs.selectStop.current.select.select.clearValue();
        }
        if (refs.inputArrive.current) {
            refs.inputArrive.current.value = '';
        }
        if (refs.inputDepart.current) {
            refs.inputDepart.current.value = '';
        }

        setStopType(undefined);
        setPreviousStopType(undefined);
        setSelectedStop(undefined);
        setArriveLatest(undefined);
        setDepartEarliest(undefined);
        setIsFlexible(undefined);
        setOrdinal(-1);

        setStopTypeOption(null);
        setSelectedStopOption(null);
        setOldStop(null);
    };

    const onSubmit = () => {
        const errors: ValidationErrorData[] = [];
        const notSet = true;

        if (!stopType) {
            errors.push({ field: t('form.timetable.itemType'), notSet });
        }
        if (!selectedStop) {
            errors.push({ field: t('form.timetable.timetableItem'), notSet });
        }
        if (!arriveLatest) {
            errors.push({ field: t('form.timetable.arriveLatest'), notSet });
        }
        if (!departEarliest) {
            errors.push({ field: t('form.timetable.departEarliest'), notSet });
        }

        if (errors.length > 0) {
            const err = parseValidationError(errors);
            notificationStore.addNotification({ level: 'error', text: err.message });
        } else {
            props.onChange(oldStop, {
                type: stopType!,
                arriveLatest: arriveLatest!,
                departEarliest: departEarliest!,
                location: selectedStop!,
                ordinal,
                isFlexible,
            });

            clearForm();
        }
    };

    return (
        <div>
            <Form.Row>
                {/* SELECT STOP TYPE */}
                <Form.Group as={Col} md={5}>
                    <Form.Label>{t('form.timetable.itemType')}</Form.Label>
                    <Select
                        ref={refs.selectStopType}
                        styles={selectStyles}
                        value={stopTypeOption}
                        placeholder={t('general.select')}
                        // NOTE! menuPortalTarget and menuPosition are vital for proper positioning
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        onChange={(selected) => {
                            if (selected) {
                                setStopType(selected.value as StopType);
                            } else {
                                setStopType(undefined);
                            }

                            setStopTypeOption(selected);
                        }}
                        options={stopTypeOptions}
                    />
                </Form.Group>
                {/* SELECT STOP */}
                <Form.Group as={Col} md={7}>
                    <Form.Label>{t('form.timetable.timetableItem')}</Form.Label>
                    <AsyncSelect
                        ref={refs.selectStop}
                        styles={selectStyles}
                        value={selectedStopOption}
                        placeholder={getStopSearchPlaceHolder()}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        loadOptions={loadStopOptions}
                        maxMenuHeight={200}
                        filterOption={(option, rawInput) => {
                            return option.label.toLowerCase().includes(rawInput.toLowerCase());
                        }}
                        isDisabled={stopType === undefined}
                        noOptionsMessage={() => t('general.noResults')}
                        onChange={(option) => {
                            if (option) {
                                setSelectedStop((option as any).data);
                            }
                            setSelectedStopOption(option as any);
                        }}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                {/* SELECT ARRIVE LATEST */}
                <Form.Group as={Col} md={5}>
                    <Form.Label>{t('form.timetable.arriveLatest')}</Form.Label>
                    <Form.Control
                        ref={refs.inputArrive}
                        type={'time'}
                        onChange={(e) => setArriveLatest(e.target.value)}
                    />
                </Form.Group>
                {/* SELECT DEPART EARLIEST */}
                <Form.Group as={Col} md={5}>
                    <Form.Label>{t('form.timetable.departEarliest')}</Form.Label>
                    <Form.Control
                        ref={refs.inputDepart}
                        type={'time'}
                        onChange={(e) => setDepartEarliest(e.target.value)}
                    />
                </Form.Group>
                {/* CHECK FLEXIBLE */}
                <Form.Group as={Col} md={2}>
                    <Form.Label>{t('form.timetable.flexible')}</Form.Label>
                    <Form.Check
                        disabled={stopType !== 'stop'}
                        checked={!!isFlexible}
                        onChange={() => setIsFlexible(!isFlexible)}
                    />
                </Form.Group>
            </Form.Row>
            <Button onClick={() => onSubmit()} style={{ marginRight: '.5em' }}>
                {oldStop ? t('form.timetable.editItem') : t('form.timetable.addItem')}
            </Button>
            <Button variant={'secondary'} onClick={() => clearForm()}>
                {t('form.timetable.clearItem')}
            </Button>
        </div>
    );
}

export default observer(TimetableStopForm);
