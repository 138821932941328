import { PropsWithChildren, useContext, useState } from 'react';

import ModalManagerContext from '../contexts/ModalManagerContext';
import { ModalConfig, ModalManagerState } from '../types';

export function ModalManager({ children }: PropsWithChildren<any>) {
    const [modal, setModal] = useState<ModalConfig | undefined>(undefined);

    const addModal = (modalConfig: ModalConfig) => {
        setModal(modalConfig);
    };

    const removeModal = () => {
        setModal(undefined);
    };

    return (
        <ModalManagerContext.Provider
            value={{
                addModal,
                removeModal,
                modal,
            }}
        >
            {children}
        </ModalManagerContext.Provider>
    );
}

export function useModal(): ModalManagerState {
    const context = useContext(ModalManagerContext);

    if (!context) {
        throw Error('Context not present in useModal hook');
    }

    return context;
}
