import React, { useEffect, useState } from 'react';
import { Navbar, Dropdown, NavLink, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import * as Fa from 'react-icons/fa';

import { useAuth0 } from '@auth0/auth0-react';

import styles from './NavBar.module.css';

import { version as appVersion } from '../../../package.json';
import WindowLink from '../../consumers/WindowLink';
import config from '../../infra/config';
import { useModal } from '../../providers/ModalManager';
import VinkaLogo from '../VinkaLogo/VinkaLogo';

const settingsIcon = (
    <IconContext.Provider value={{ className: styles.settingsIcon }}>
        <Fa.FaCog />
    </IconContext.Provider>
);

export default function NavBar(): JSX.Element {
    const { t, i18n } = useTranslation();
    const { isAuthenticated, loginWithPopup, logout, user } = useAuth0();
    const { addModal } = useModal();
    const [username, setUsername] = useState('');

    // Set pretty username
    useEffect(() => {
        (async () => {
            let shortUsername =
                user?.preferred_username || user?.given_name || user?.nickname || 'User';
            if (shortUsername.length > 15) {
                shortUsername = shortUsername.slice(0, 13) + '...';
            }
            setUsername(shortUsername);
        })();
    }, [user]);

    const setLanguage = (locale: string) => {
        localStorage.setItem('locale', locale);
        i18n.changeLanguage(locale);
    };

    const onUserButtonClick = async () => {
        if (isAuthenticated) {
            addModal({
                title: t('modal.logout.title'),
                confirmText: t('modal.logout.confirm'),
                onConfirm: () => {
                    logout();
                },
                onCancel: true,
            });
        } else {
            await loginWithPopup();
        }
    };

    return (
        <Navbar className={`${styles.vinkaNavBar} navbar-dark`}>
            <Navbar.Brand className={styles.vinkaBrand}>
                <VinkaLogo />
                <span id={'vinkaBrandAppNameSpan'}>Flex</span>
            </Navbar.Brand>

            {isAuthenticated && (
                <>
                    <WindowLink entityType={'service'} panelType={'lists'}>
                        <NavLink className={styles.navLink}>
                            <Fa.FaBusAlt />
                            {t('title.service', { count: 0 })}
                        </NavLink>
                    </WindowLink>

                    <WindowLink entityType={'stop'} panelType={'lists'}>
                        <NavLink className={styles.navLink}>
                            <Fa.FaListAlt />
                            {t('title.stop', { count: 0, format: 'title' })}
                        </NavLink>
                    </WindowLink>

                    <WindowLink entityType={'area'} panelType={'lists'}>
                        <NavLink className={styles.navLink}>
                            <Fa.FaGlobe />
                            {t('title.area', { count: 0 })}
                        </NavLink>
                    </WindowLink>
                </>
            )}

            <Button
                style={{ marginLeft: 'auto' }}
                variant={'outline-light'}
                onClick={onUserButtonClick}
            >
                {user && user.picture && (
                    <img
                        style={{ maxWidth: '1.25rem', maxHeight: '1.25rem' }}
                        src={user.picture}
                        className={['rounded-circle', 'align-middle'].join(' ')}
                    />
                )}
                <span className={'align-middle'}> {user ? username : t('button.login')}</span>
            </Button>
            <Dropdown className={styles.settingsDropdown}>
                <Dropdown.Toggle
                    className={styles.settingsDropdownToggle}
                    id={'settings-dropdown-toggle'}
                >
                    {settingsIcon}
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight={true} className={styles.settingsDropdownMenu}>
                    <Dropdown.Item className={styles.settingsDropdownItem} disabled={true}>
                        v{appVersion}
                    </Dropdown.Item>
                    <Dropdown.Divider className={styles.settingsDropdownDivider} />
                    <Dropdown.Item
                        className={styles.settingsDropdownItem}
                        disabled={!isAuthenticated}
                    >
                        <div onClick={() => logout({ returnTo: config.app.rootUrl })}>
                            {t('button.logout')}
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Divider className={styles.settingsDropdownDivider} />
                    <Dropdown.Item
                        className={styles.settingsDropdownItem}
                        onClick={() => setLanguage('fi')}
                    >
                        Suomi
                    </Dropdown.Item>
                    <Dropdown.Item
                        className={styles.settingsDropdownItem}
                        onClick={() => setLanguage('en')}
                    >
                        English
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Navbar>
    );
}
