import { PropsWithChildren, useContext, useState } from 'react';

import WindowManagerContext from '../contexts/WindowManagerContext';
import {
    ManagedDetailWindowProps,
    ManagedWindow,
    ManagedWindowConfig,
    ManagedWindowProps,
    WindowManagerState,
    WindowPanelMap,
} from '../types';
import { decodeWindowId, encodeWindowId } from '../utils';
import * as windows from '../windows';

/**
 * Creates a window from a window config
 * @param config
 * @param windowId
 */
const configToWindow = (config: ManagedWindowConfig, windowId: string): ManagedWindow => {
    let component: ManagedWindow | undefined;

    const baseProps: ManagedWindowProps = {
        windowId,
        key: windowId,
        custom: config.custom,
    };

    const detailProps: ManagedDetailWindowProps = {
        ...baseProps,
        entityId: config.isNew ? windowId : config.entityId!,
        isNew: config.isNew,
    };

    if (config.panelType === 'details' && !detailProps.entityId) {
        throw Error(
            `No entity id set for detail window of ${config.entityType}. Is new: ${!!config.isNew}`
        );
    }

    // STOP
    if (config.panelType === 'lists' && config.entityType === 'stop') {
        component = <windows.StopsList {...baseProps} />;
    } else if (config.panelType === 'details' && config.entityType === 'stop') {
        component = <windows.StopDetail {...detailProps} />;
    }
    // SERVICE
    else if (config.panelType === 'lists' && config.entityType === 'service') {
        component = <windows.ServicesList {...baseProps} />;
    } else if (config.panelType === 'details' && config.entityType === 'service') {
        component = <windows.ServiceDetail {...detailProps} />;
    }
    // TIMETABLE
    else if (config.panelType === 'details' && config.entityType === 'timetable') {
        component = <windows.TimetableDetail {...detailProps} />;
    }
    // AREA
    else if (config.panelType === 'lists' && config.entityType === 'area') {
        component = <windows.AreasList {...baseProps} />;
    } else if (config.panelType === 'details' && config.entityType === 'area') {
        component = <windows.AreaDetail {...detailProps} />;
    }

    if (component) {
        return component;
    } else {
        throw Error('Could not create window with config ' + JSON.stringify(config));
    }
};

/**
 * Gets windows from window ids in query params by panel type. Does not return windows containing
 * "new" unpersisted entities. Meant to be used upon WindowManager initialization.
 */
const getInitialWindows = (panelType: keyof WindowPanelMap): ManagedWindow[] => {
    const params = new URLSearchParams(window.location.search.slice(1));
    const windowIds = (params.get(panelType) || '').split(',').filter((val) => val !== '');
    return windowIds.reduce((managedWindows, id) => {
        const windowConfig = decodeWindowId(panelType, id);
        // Don't push new windows. No point to show unsaved data
        if (!windowConfig.isNew) {
            managedWindows.push(configToWindow(windowConfig, id));
        }
        return managedWindows;
    }, [] as ManagedWindow[]);
};

/**
 * A provider for accessing and manipulating state of the windows in the application.
 * @param children
 * @constructor
 */
export function WindowManager({ children }: PropsWithChildren<any>) {
    const [details, setDetails] = useState<ManagedWindow[]>(getInitialWindows('details'));
    const [utils, setUtils] = useState<ManagedWindow[]>(getInitialWindows('utils'));
    const [lists, setLists] = useState<ManagedWindow[]>(getInitialWindows('lists'));

    // Helper for making state iterable and dynamically accessible
    const panelState: WindowPanelMap = {
        get details() {
            return details;
        },
        set details(newDetails) {
            setDetails(newDetails);
        },
        get utils() {
            return utils;
        },
        set utils(newUtils) {
            setUtils(newUtils);
        },
        get lists() {
            return lists;
        },
        set lists(newLists) {
            setLists(newLists);
        },
    };

    const addWindow: WindowManagerState['addWindow'] = (config) => {
        const windowId = encodeWindowId(config);
        const panelWindows = panelState[config.panelType];
        const existingWindow = panelWindows.find((w) => w.props.windowId === windowId);

        if (existingWindow) {
            panelState[config.panelType] = [
                existingWindow,
                ...panelWindows.filter((w) => w.props.windowId !== windowId),
            ];
        } else {
            const managedWindow = configToWindow(config, windowId);
            panelState[config.panelType] = [managedWindow, ...panelWindows];
        }
    };

    const removeWindow = (panelType: keyof WindowPanelMap, windowId: string) => {
        panelState[panelType] = panelState[panelType].filter((w) => w.props.windowId !== windowId);
    };

    const updateWindowId = (oldWindowId: string, newEntityId: string) => {
        const config = decodeWindowId('details', oldWindowId);
        const panel = panelState[config.panelType];

        const index = panel.findIndex((window) => window.props.windowId === oldWindowId);

        if (index === -1) {
            throw Error(
                `Could not find a window from panel ${config.panelType} with windowId ${oldWindowId}`
            );
        }

        config.entityId = newEntityId;
        config.isNew = undefined;
        panelState[config.panelType] = [
            ...panel.slice(0, index),
            configToWindow(config, encodeWindowId(config)),
            ...panel.slice(index + 1, panel.length),
        ];
    };

    return (
        <WindowManagerContext.Provider
            value={{
                details,
                utils,
                lists,
                addWindow,
                removeWindow,
                updateWindowId,
            }}
        >
            {children}
        </WindowManagerContext.Provider>
    );
}

export function useWindowManager() {
    const context = useContext(WindowManagerContext);

    if (!context) {
        throw Error('Context not present in useRootStore hook');
    }

    return context;
}
