import { TFunctionResult } from 'i18next';
import { useTranslation } from 'react-i18next';

import { LocalizableString } from '../types';
import { ValidationError } from './errors';

export interface ValidationErrorData {
    field?: LocalizableString;
    notSet?: true;
    message?: LocalizableString;
}

export interface UseValidationResult {
    parseValidationError: (errors: ValidationErrorData[]) => ValidationError;
}

export function useValidation(): UseValidationResult {
    const { t } = useTranslation();

    return {
        parseValidationError: (errors) => {
            const errorParagraphs: TFunctionResult[] = [];
            const notSetFields = [];
            const unknownErrorFields = [];

            for (const error of errors) {
                if (error.notSet && error.field) {
                    notSetFields.push(error.field);
                } else if (error.message) {
                    errorParagraphs.push(error.message);
                } else if (error.field) {
                    unknownErrorFields.push(error.field);
                }
            }

            if (notSetFields.length > 0) {
                errorParagraphs.push(
                    t('validation.notSetError', { fields: notSetFields.join(', ') })
                );
            }

            if (unknownErrorFields.length > 0) {
                errorParagraphs.push(
                    t('validation.unknown', { fields: unknownErrorFields.join(', ') })
                );
            }

            const text =
                errorParagraphs.length > 0 ? errorParagraphs.join('\n') : t('validation.noInfo');
            return new ValidationError(text);
        },
    };
}

export function validateCoordinates(coordinatePair: any): boolean {
    if (!Array.isArray(coordinatePair)) {
        return false;
    } else if (coordinatePair.length !== 2) {
        return false;
    } else if (typeof coordinatePair[0] !== 'number' || typeof coordinatePair[1] !== 'number') {
        return false;
    }

    return true;
}

export function validatePolygon(polygonWrapper: any): boolean {
    if (!Array.isArray(polygonWrapper)) {
        return false;
    } else if (polygonWrapper.length !== 1) {
        return false;
    }

    const polygon = polygonWrapper[0];

    if (!Array.isArray(polygon)) {
        return false;
    } else if (polygon.length < 3) {
        return false;
    }

    for (const coordinatePair of polygon) {
        if (!validateCoordinates(coordinatePair)) {
            return false;
        }
    }

    return true;
}
