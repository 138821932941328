import dayjs, { Dayjs } from 'dayjs';
import flatpickr from 'flatpickr';
import { Finnish } from 'flatpickr/dist/l10n/fi.js';
import { forwardRef } from 'react';
import Flatpickr from 'react-flatpickr';
import DatePicker from 'react-flatpickr';
import { useTranslation } from 'react-i18next';

import 'flatpickr/dist/flatpickr.css';

export interface VinkaDatePickerProps {
    enableTime?: boolean;
    defaultValue?: Dayjs | Dayjs[];
    onChange: (dates: Dayjs[]) => void;
    pickerType?: 'range' | 'single';
    className?: string;
}

type PickerDateType = Date | Date[] | undefined;

function convertDefaultDate(date: VinkaDatePickerProps['defaultValue']): PickerDateType {
    if (date === undefined) {
        return undefined;
    } else if (Array.isArray(date)) {
        return date.map((d) => d.toDate());
    } else {
        return date.toDate();
    }
}

export default forwardRef<DatePicker, VinkaDatePickerProps>(function VinkaDatePicker(
    props: VinkaDatePickerProps,
    ref
) {
    const { i18n } = useTranslation();

    const locale =
        i18n.language === 'fi' ? Object.assign({}, Finnish, { rangeSeparator: ' - ' }) : undefined;

    const options: flatpickr.Options.Options = {
        mode: props.pickerType || 'single',
        formatDate: (d) => dayjs(d).format(props.enableTime ? 'L LT' : 'L'),
    };

    // Set locale to options conditionally to avoid warnings from flatpickr.
    if (locale) {
        options.locale = locale;
    }

    const onChange = (dates: Date[]) => {
        const properDates: Dayjs[] = [];

        if (dates[0]) {
            properDates.push(dayjs(dates[0]).startOf('day'));
        }

        if (dates[1]) {
            properDates.push(dayjs(dates[1]).endOf('day'));
        }

        props.onChange(properDates);
    };

    return (
        <Flatpickr
            data-enable-time={!!props.enableTime}
            className={props.className || 'form-control'}
            onChange={onChange}
            value={convertDefaultDate(props.defaultValue)}
            options={options}
            ref={ref}
        />
    );
});
