/* eslint-disable camelcase */

export default {
    title: {
        service: 'Service {{name}}',
        service_plural: 'Services',
        service_new: 'New service',
        stop: 'Stop {{name}}',
        stop_plural: 'Stops',
        stop_new: 'New stop',
        area: 'Area {{name}}',
        area_plural: 'Areas',
        area_new: 'New Area',
        timetable: 'Timetable {{name}}',
        timetable_new: 'New timetable',
    },
    error: {
        generic: 'Something went wrong',
    },
    button: {
        login: 'Login',
        logout: 'Logout',
        createService: 'Create service',
        createTimetable: 'Create timetable',
        createStop: 'Create stop',
        createArea: 'Create area',
        validate: 'Validate',
    },
    general: {
        id: 'Id',
        name: 'Name',
        description: 'Description',
        validity: 'Validity',
        filter: 'Filter...',
        select: 'Select...',
        noResults: 'No results',
        vehicle: 'Vehicle',
        coordinates: 'Coordinates',
        startTyping: 'Please start typing',
        loading: 'Loading...',
        address: 'Address',
        addressSearch: 'Address search',
        displayArchived: 'Display archived',
        copyOf: 'Copy: {{name}}',
    },
    table: {
        timetable: {
            startTime: 'Depart at',
            startLocation: 'Depart from',
            indefinitely: 'Indefinitely',
        },
        service: {
            validIndefinitely: 'Valid indefinitely',
            validUntil: 'Valid until {{date}}',
            validityEnded: 'Expired {{date}}',
        },
    },
    form: {
        timetable: {
            // Labels
            recurrence: 'Recurrence',
            weekday_short: {
                mon: 'Mon',
                tue: 'Tue',
                wed: 'Wed',
                thu: 'Thu',
                fri: 'Fri',
                sat: 'Sat',
                sun: 'Sun',
            },
            omittedDates: 'Omitted dates',
            arriveLatest: 'Arrival',
            departEarliest: 'Departure',
            serviceId: 'Service id',
            routePlan: 'Route plan',
            timetableItem: 'Timetable item',
            itemType: 'Item type',
            flexible: 'Flexible',
            validIndefinitely: 'Valid indefinitely',
            createRoute: 'Create route (for testing)',
            validateTimetable: 'Validate timetable',

            // Buttons
            addItem: 'Add item',
            editItem: 'Edit item',
            clearItem: 'Clear item',
            addOmission: 'Add omission',

            // Placeholders
            selectTypeFirst: 'Select type first',
            searchStop: 'Search for stop...',
            searchArea: 'Search for area...',
            selectDate: 'Select date',
        },
        stop: {
            alias: 'Alias',
            street: 'Street',
            streetNumber: 'Number',
            city: 'City',
        },
        mapContext: {
            closeMenu: 'Close menu',
            addLocation: 'Add location',
            cancelEditing: 'Cancel editing area',
            finishArea: 'Finish editing area',
            removePrevious: 'Remove previous location',
        },
    },
    modal: {
        defaultConfirm: 'Ok',
        defaultCancel: 'Cancel',
        logout: {
            title: 'Do you want to log out?',
            confirm: 'Log out',
        },
        removeWindow: {
            title: 'Do you want to close the window?',
            body: 'You have unsaved changes',
            confirm: 'Close window',
        },
        archiveConfirm: {
            service: {
                title: 'Are you sure?',
                body: 'Do you want to archive service "{{name}}"?',
                confirm: 'Archive service',
            },
            timetable: {
                title: 'Are you sure?',
                body: 'Do you want to archive timetable "{{name}}"?',
                confirm: 'Archive timetable',
            },
            stop: {
                title: 'Are you sure?',
                body: 'Do you want to archive stop "{{name}}"?',
                confirm: 'Archive stop',
            },
            area: {
                title: 'Are you sure?',
                body: 'Do you want to archive area "{{name}}"?',
                confirm: 'Archive area',
            },
        },
    },
    notification: {
        error: {
            // Common errors from requests
            connRefused: 'Connection to server failed.',
            badRequest: 'Bad request to server {{message}}.',
            notFound: '{{entity}} {{id}} not found.',
            forbidden: 'Authentication failed. Try logging in again.',
            serverInternal: 'The server encountered an unexpected error.',
            eventTimeout: 'The request timed out',

            // Catch-all error
            unexpected: 'Unexpected error occurred.',

            mapEditOccupied: 'Map is already being edited',
            exitMapEditBeforeClose: 'Exit map editing tool before closing window',
            routeCreateFailed: 'Route creation failed: {{message}}',
            archiveConflict: {
                areaUsedByTimetable: 'The area is used by active timetables',
                stopUsedByTimetable: 'The stop is used by active timetables',
                serviceHasActiveTimetables: 'The service contains active timetables',
                timetableIsActive: 'The timetable is still active',
            },
            updateConflict: {
                tripsMustBeUnscheduled:
                    'Update failed. The following trips must first be unscheduled: {{tripIds}}',
            },
            timetableValidation: {
                tooTight: "The timetable's stops are too dense, and could not be performed on time",
            },
        },
        warn: {
            cannotSave: 'Cannot save now, please wait.',
            stopEditFailed:
                'Stop could not be edited, the stop may already be removed from the timetable.',
            stopOwnerFrozen: 'Cannot edit a pinned stop',
            areaOwnerFrozen: 'Cannot edit a pinned area',
            timetableValidation: {
                saveBefore: 'Timetable must be saved before validation',
                noRoutes:
                    'The timetable was validated, but no routes were created for the given date range',
            },
        },
        info: {
            mapEditEnabled: 'The map is in editing mode',
            routeCreateSuccess: 'Route {{id}} created',
            timetableValidated: 'Timetable validated',
        },
    },
    validation: {
        errorInForm: 'Error in form:\n {{errors}}',
        noInfo: 'Application error, no additional info',
        notSetError: 'Fields {{fields}} not set.',
        unknown: 'Fields {{fields}} have unknown error.',
        routePlanEmpty: 'Route plan is empty.',
    },
    tooltip: {
        timetableValidation:
            'Validation creates the routes temporarily for the given date range, ' +
            'and ensures that they are possible to be performed',
    },
};
