import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '../providers/RootStoreProvider';
import { LocalizableString, WindowEntityType } from '../types';
import { ValidationError } from '../utils/errors';

export { default as StopDetail } from './StopDetailWindow/StopDetailWindow';
export { default as StopsList } from './StopsListWindow/StopsListlWindow';
export { default as ServicesList } from './ServicesListWindow/ServicesListWindow';
export { default as ServiceDetail } from './ServiceDetailWindow/ServiceDetailWindow';
export { default as TimetableDetail } from './TimetableDetailWindow/TimetableDetailWindow';
export { default as AreasList } from './AreasListWindow/AreasListWindow';
export { default as AreaDetail } from './AreaDetailWindow/AreaDetailWindow';

// no-shadow triggers incorrectly with
// eslint-disable-next-line no-shadow
export enum WindowState {
    // When window is initializing
    INIT = 'INIT',

    // Default window state, nothing is happening
    IDLE = 'IDLE',

    // Window is fetching data to display
    UPDATING = 'UPDATING',

    // Window has unsaved changes
    DIRTY = 'DIRTY',

    // Window is saving changes
    SAVING = 'SAVING',

    // Generic error state
    ERROR = 'ERROR',
}

export function useSaveHandler(
    windowState: WindowState,
    setWindowState: (state: WindowState) => void,
    setIdleOnSuccess: boolean,
    saveHandler: () => Promise<void>,
    errorHandler: (err: any) => Promise<void>
): () => Promise<void> {
    const { notificationStore } = useRootStore();
    const { t } = useTranslation();

    return async () => {
        // Exit if window state invalid (e.g. we don't want to be able to save when fetching data)
        if ([WindowState.SAVING, WindowState.UPDATING].includes(windowState)) {
            notificationStore.addNotification({
                level: 'warn',
                autoConfirm: true,
                text: t('notification.warn.cannotSave'),
            });
            return Promise.resolve();
        }

        setWindowState(WindowState.SAVING);
        try {
            await saveHandler();

            // Need to make setting window state optional, because otherwise we might be performing a React state update
            // on an unmounted component. If saveHandler provided by a component uses WindowManager.updateWindowId, the
            // component is re-created. We expect this to be done in windows with new entities, so that their real
            // windowIds are updated to query params.
            if (setIdleOnSuccess) {
                setWindowState(WindowState.IDLE);
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Error in save handler', err);
            setWindowState(WindowState.ERROR);
            await errorHandler(err);
        }
    };
}

export interface ErrorHandlerOptions {
    // Allow caller to handle error by HTTP response status code.
    // Return value indicates that the error was handled.
    status?: {
        [code: number]: (err: AxiosError) => boolean;
    };
    // Allow caller to handle error by message.
    // Return value indicates that the error was handled.
    message?: {
        [msg: string]: (err: any) => boolean;
    };
    entityId?: string | number;
}

export interface UseErrorHandlerResult {
    commonErrorHandler: (err: any, handlerOptions?: ErrorHandlerOptions) => void;
}

export function useErrorHandler(options: {
    entityId?: string;
    entityType: WindowEntityType;
}): UseErrorHandlerResult {
    const { notificationStore } = useRootStore();
    const { t } = useTranslation();

    const commonErrorHandler: UseErrorHandlerResult['commonErrorHandler'] = (
        err,
        handlerOptions
    ) => {
        const id = handlerOptions?.entityId || options.entityId;

        if (err.message && handlerOptions?.message && handlerOptions.message[err.message]) {
            const handled = handlerOptions.message[err.message](err);
            if (handled) {
                return;
            }
        }

        const addNotification = (text: LocalizableString) =>
            notificationStore.addNotification({ level: 'error', autoConfirm: true, text });

        if (err instanceof ValidationError) {
            // No autoConfirm here
            notificationStore.addNotification({ level: 'error', text: err.message });
            return;
        }

        if (axios.isAxiosError(err)) {
            // Allow caller to handle error themselves by status code
            if (
                err.response?.status &&
                handlerOptions?.status &&
                handlerOptions.status[err.response.status]
            ) {
                const handled = handlerOptions.status[err.response.status](err);
                if (handled) {
                    return;
                }
            }

            switch (err.response?.status) {
                case 400:
                    addNotification(
                        t('notification.error.badRequest', { message: err.response?.data?.message })
                    );
                    return;
                case 403:
                    addNotification(t('notification.error.forbidden'));
                    return;
                case 404:
                    addNotification(
                        t('notification.error.notFound', {
                            entity: t(`title.${options.entityType}`),
                            id,
                        })
                    );
                    return;
                case 500:
                    addNotification(t('notification.error.serverInternal'));
                    return;
                case 504:
                    if (err.response.data?.message === 'event_timeout') {
                        addNotification(t('notification.error.eventTimeout'));
                        return;
                    }
            }
        }

        if (err.message === 'Network Error') {
            addNotification(t('notification.error.connRefused'));
            return;
        }

        notificationStore.addUnexpected(err);
    };

    return { commonErrorHandler };
}
