import { PropsWithChildren, useContext } from 'react';

import RootStoreContext from '../contexts/RootStoreContext';
import RootStore from '../stores/RootStore';

export default function RootStoreProvider(props: PropsWithChildren<any>) {
    const store = new RootStore();
    return <RootStoreContext.Provider value={store}>{props.children}</RootStoreContext.Provider>;
}

export function useRootStore(): RootStore {
    const context = useContext(RootStoreContext);

    if (!context) {
        throw Error('Context not present in useRootStore hook');
    }

    return context;
}
