import dayjs from 'dayjs';
import { makeAutoObservable, ObservableMap } from 'mobx';

import { NotificationConfig, INotification } from '../types/notification';
import RootStore from './RootStore';

export class NotificationStore {
    public notifications = new ObservableMap<string, INotification>();

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, { rootStore: false });
    }

    public addNotification(config: NotificationConfig) {
        const createdAt = dayjs();
        const id = `notification-${createdAt.toISOString()}`;
        this.notifications.set(id, { ...config, createdAt, id });
    }

    public addUnexpected(e: any) {
        // eslint-disable-next-line no-console
        console.error('Unexpected error', e);
        this.addNotification({
            text: 'notification.error.unexpected',
            applyTranslation: true,
            level: 'error',
            autoConfirm: true,
        });
    }

    public removeNotification(id: string) {
        this.notifications.delete(id);
    }
}
