import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import * as _ from 'lodash';
import { get, IMapEntries, makeAutoObservable, ObservableMap, values } from 'mobx';

import { useAuth0 } from '@auth0/auth0-react';

import config from '../infra/config';
import {
    Address,
    APIArea,
    APIService,
    APIStop,
    APITimetable,
    AreaMap,
    Data,
    GaVehicle,
    ILocationArea,
    ILocationStop,
    ITimetable,
    MappedService,
    PlainMap,
    ScheduledStop,
    ServiceMap,
    StopMap,
    TimetableMap,
    UIArea,
    UIService,
    UIStop,
    UITimetable,
} from '../types';
import translate from '../utils/translator';

const axiosConfig: AxiosRequestConfig = {
    baseURL: config.api.url,
    headers: { 'Content-Type': 'application/json' },
};

export class DataStore {
    public services: ServiceMap = new ObservableMap();
    public timetables: TimetableMap = new ObservableMap();
    public stops: StopMap = new ObservableMap();
    public areas: AreaMap = new ObservableMap();

    public http: AxiosInstance;

    constructor() {
        // Using this hook here causes bug where list views are cleared of data as soon as they're populated.
        // The data doesn't seem to disappear from the store, however.
        const { getAccessTokenSilently, logout } = useAuth0();

        this.http = axios.create(axiosConfig);
        this.http.interceptors.request.use(async (req) => {
            const token = await getAccessTokenSilently({ timeoutInSeconds: 5 });
            req.headers.Authorization = 'Bearer ' + token;
            return req;
        });
        this.http.interceptors.response.use(undefined, async (err) => {
            if (err.response.status === 401) {
                logout({ returnTo: config.app.rootUrl });
            } else {
                throw err;
            }
        });

        makeAutoObservable(this, { http: false });
    }

    // ----------------------------------
    // GET ALL
    // ----------------------------------

    async getServices(useCache = false): Promise<ServiceMap> {
        if (useCache) {
            if (values(this.services).length > 0) {
                return this.services;
            }
        }

        const response = await this.http.get<Data<APIService[]>>('/service', {
            params: { includeArchived: true },
        });
        const services = response.data.data.map(translate.service.fromApi);
        this.servicesToStore(services);

        return this.services;
    }

    async getStops(useCache = false): Promise<StopMap> {
        if (useCache) {
            if (values(this.stops).length > 0) {
                return this.stops;
            }
        }

        const apiStops = await this.http.get<Data<APIStop[]>>('/stop', {
            params: { includeArchived: true },
        });
        const uiStops = apiStops.data.data.map((stop) => translate.stop.fromApi(stop));
        this.stopsToStore(uiStops);

        return this.stops;
    }

    async getAreas(useCache = false): Promise<AreaMap> {
        if (useCache) {
            if (values(this.areas).length > 0) {
                return this.areas;
            }
        }

        const apiAreas = await this.http.get<Data<APIArea[]>>('/area', {
            params: { includeArchived: true },
        });
        const uiAreas = apiAreas.data.data.map((area) => translate.area.fromApi(area));
        this.areasToStore(uiAreas);

        return this.areas;
    }

    // ----------------------------------
    // GET ONE
    // ----------------------------------

    async getService(id: string, useCache = true): Promise<MappedService> {
        if (useCache) {
            const cachedService = this.services.get(id);
            if (cachedService) {
                return cachedService;
            }
        }

        const response = await this.http.get('/service/' + id);
        this.serviceToStore(translate.service.fromApi(response.data.data));
        return this.services.get(id)!;
    }

    async getTimetable(id: string, useCache = true): Promise<UITimetable> {
        if (useCache) {
            const cachedTimetable = this.timetables.get(id);
            if (cachedTimetable) {
                return cachedTimetable;
            }
        }

        const response = await this.http.get('/timetable/' + id);
        const translated = translate.timetable.fromApi(response.data.data);
        this.timetableToStore(translated);
        return this.timetables.get(translated.id)!;
    }

    async getStop(id: string): Promise<UIStop> {
        const cachedStop = this.stops.get(id);
        if (cachedStop) {
            return cachedStop;
        }

        const apiStop = await this.http.get<Data<APIStop>>('/stop/' + id);
        const uiStop = translate.stop.fromApi(apiStop.data.data);
        this.stops.set(uiStop.id, uiStop);
        return this.stops.get(uiStop.id)!;
    }

    async getArea(id: string): Promise<UIArea> {
        const cachedArea = this.areas.get(id);
        if (cachedArea) {
            return cachedArea;
        }

        const apiArea = await this.http.get<Data<APIArea>>('/area/' + id);
        const uiArea = translate.area.fromApi(apiArea.data.data);
        this.areas.set(uiArea.id, uiArea);
        return this.areas.get(uiArea.id)!;
    }

    // ----------------------------------
    // CREATE
    // ----------------------------------

    async createService(service: UIService): Promise<MappedService> {
        const data = translate.service.toUnidentified(service);
        const response = await this.http.post<Data<APIService>>('/service', { data });
        const newService = translate.service.fromApi(response.data.data);
        this.serviceToStore(newService);

        return get(this.services, newService.id)!;
    }

    // Creates timetable for an existing service.
    async createTimetable(timetable: UITimetable): Promise<UITimetable> {
        const data: ITimetable<string | undefined, any> = timetable;
        delete data.id;

        const response = await this.http.post<Data<APITimetable>>('/timetable', { data });
        const newTimetable = translate.timetable.fromApi(response.data.data);
        this.timetableToStore(newTimetable);
        return this.timetables.get(newTimetable.id)!;
    }

    async createStop(stop: UIStop): Promise<UIStop> {
        const data: ILocationStop<string | undefined, Dayjs> = stop;
        delete data.id;

        const response = await this.http.post<Data<APIStop>>('/stop', { data });
        const newStop = translate.stop.fromApi(response.data.data);
        this.stops.set(newStop.id, newStop);
        return this.stops.get(newStop.id)!;
    }

    async createArea(area: UIArea): Promise<UIArea> {
        const data: ILocationArea<string | undefined, Dayjs> = area;
        delete data.id;

        const response = await this.http.post<Data<APIArea>>('/area', { data });
        const newArea = translate.area.fromApi(response.data.data);
        this.areas.set(newArea.id, newArea);
        return this.areas.get(newArea.id)!;
    }

    // ----------------------------------
    // COPY
    // ----------------------------------

    /**
     * Creates a copy of a service
     * @param id the id of the service to copy
     * @param name the name for the copied service. This parameter exists as we can't use hooks in
     * the store for localization.
     */
    async copyService(id: string, name: string): Promise<MappedService> {
        const apiService = await this.http.get<Data<APIService>>('/service/' + id);
        const data = translate.service.toUnidentified(apiService.data.data);
        data.name = name;
        // Set a default validity for timetables, so that they are not routed at the same dates as
        // the originals.
        data.timetables.forEach((timetable) => {
            timetable.validity = {
                from: dayjs.utc('3000-01-01').startOf('day').toISOString(),
                to: dayjs.utc('3000-01-01').endOf('day').toISOString(),
            };
        });

        const created = await this.http.post<Data<APIService>>('/service', { data });
        const uiService = translate.service.fromApi(created.data.data);
        this.serviceToStore(uiService);

        return get(this.services, uiService.id)!;
    }

    // ----------------------------------
    // UPDATE
    // ----------------------------------

    async updateService(service: UIService) {
        const response = await this.http.put<Data<APIService>>('/service/' + service.id, {
            data: service,
        });
        this.serviceToStore(translate.service.fromApi(response.data.data));
    }

    async updateTimetable(timetable: UITimetable) {
        if (timetable.serviceId === undefined) {
            throw Error('timetable cannot be updated without serviceId');
        } else if (timetable.id === undefined) {
            throw Error('timetable cannot be updated without id');
        }

        const response = await this.http.put<Data<APITimetable>>('/timetable/' + timetable.id, {
            data: timetable,
        });
        this.timetableToStore(translate.timetable.fromApi(response.data.data));
    }

    async updateStop(stop: UIStop) {
        const response = await this.http.put<Data<APIStop>>('/stop/' + stop.id, { data: stop });
        const translated = translate.stop.fromApi(response.data.data);
        this.stops.set(translated.id, translated);
    }

    async updateArea(area: UIArea) {
        const response = await this.http.put<Data<APIArea>>('/area/' + area.id, { data: area });
        const translated = translate.area.fromApi(response.data.data);
        this.areas.set(translated.id, translated);
    }

    // ----------------------------------
    // DELETE
    // ----------------------------------

    public async deleteService(serviceId: string) {
        await this.http.delete('/service/' + serviceId);
        const service = this.services.get(serviceId)!;
        service.deletedAt = dayjs();
    }

    public async deleteTimetable(serviceId: string, timetableId: string) {
        await this.http.delete('/timetable/' + timetableId);
        const timetable = this.services.get(serviceId)!.timetables.get(timetableId)!;
        timetable.deletedAt = dayjs();
        this.timetables.set(timetableId, timetable);
    }

    public async deleteStop(stopId: string) {
        await this.http.delete('/stop/' + stopId);
        const stop = this.stops.get(stopId)!;
        stop.deletedAt = dayjs();
    }

    public async deleteArea(areaId: string) {
        await this.http.delete('/area/' + areaId);
        const area = this.areas.get(areaId)!;
        area.deletedAt = dayjs();
    }

    // ----------------------------------
    // TRANSLATE
    // ----------------------------------

    // Stores multiple services to store. The intention here is to cause a minimal amount notification to observers.
    public servicesToStore(services: UIService[]): void {
        // Reduce stops and areas from all timetables of all services.
        const [stopMap, areaMap] = services.reduce<[PlainMap<UIStop>, PlainMap<UIArea>]>(
            ([accumulatedStops, accumulatedAreas], service) => {
                const stopEntries = _.flatten<ScheduledStop<string>>(
                    service.timetables.map((tt) => tt.stops)
                ).map((ttStop) => [ttStop.location.id, ttStop.location]);
                const areaEntries = _.flatten<ScheduledStop<string>>(
                    service.timetables.map((tt) => tt.areas)
                ).map((ttStop) => [ttStop.location.id, ttStop.location]);

                Object.assign(accumulatedStops, Object.fromEntries(stopEntries));
                Object.assign(accumulatedAreas, Object.fromEntries(areaEntries));

                return [accumulatedStops, accumulatedAreas];
            },
            [{}, {}]
        );
        this.stops.merge(stopMap);
        this.areas.merge(areaMap);

        // Reduce all service timetables into single map, then merge the map with timetables observable map.
        const timetableMap = services.reduce((map: any, service) => {
            const serviceTimetableMap = Object.fromEntries(
                service.timetables.map((tt) => [tt.id, tt])
            );
            return Object.assign(map, serviceTimetableMap);
        }, {});
        this.timetables.merge(timetableMap);

        // Finally, create a plain map of services, and merge into observable map
        const serviceMap = Object.fromEntries(
            services.map((service) => [
                service.id,
                Object.assign(service, {
                    timetables: new ObservableMap(
                        service.timetables.map((timetable) => [
                            timetable.id,
                            this.timetables.get(timetable.id)!,
                        ]) as IMapEntries
                    ),
                }),
            ])
        );
        this.services.merge(serviceMap);
    }

    public stopsToStore(stops: UIStop[]): void {
        this.stops.merge(_.keyBy(stops, 'id'));
    }

    public areasToStore(areas: UIArea[]): void {
        this.areas.merge(_.keyBy(areas, 'id'));
    }

    // This must be a public action as it modifies the store
    public serviceToStore(service: UIService): void {
        this.services.set(
            service.id,
            Object.assign({}, service, { timetables: new ObservableMap() })
        );

        // TODO we could use ObservableMap.merge here instead
        for (const timetable of service.timetables) {
            this.timetableToStore(timetable);
        }
    }

    public timetableToStore(timetable: UITimetable) {
        this.timetables.set(timetable.id, timetable);
        this.services.get(timetable.serviceId)?.timetables.set(timetable.id, timetable);

        timetable.stops.forEach((stop, index) => {
            this.stops.set(stop.location.id, stop.location);
            this.timetables.get(timetable.id)!.stops[index].location = this.stops.get(
                stop.location.id
            )!;
        });
        timetable.areas.forEach((area, index) => {
            this.areas.set(area.location.id, area.location);
            this.timetables.get(timetable.id)!.areas[index].location = this.areas.get(
                area.location.id
            )!;
        });
    }

    // ----------------------------------
    // MISC
    // ----------------------------------

    public async geocode(search: string): Promise<Address[]> {
        const response = await this.http.get<Data<Address[]>>('/geocode', { params: { search } });
        return response.data.data;
    }

    public async getVehicles(): Promise<GaVehicle[]> {
        const response = await this.http.get<Data<GaVehicle[]>>('/vehicles');
        return response.data.data;
    }

    public async createRoute(timetableId: string, startDate: Dayjs): Promise<any> {
        const response = await this.http.post(`/timetable/${timetableId}/create-route`, undefined, {
            params: { startDate: startDate.toISOString() },
        });
        return response.data.data;
    }

    public async hypoGenerateRoutes(
        timetableId: string,
        startDate: Dayjs,
        endDate: Dayjs
    ): Promise<any[]> {
        const response = await this.http.post(
            `/timetable/${timetableId}/hypo-generate-routes`,
            undefined,
            {
                params: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
            }
        );
        return response.data.data;
    }
}
