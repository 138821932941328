import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Fa from 'react-icons/fa';

import AddressInput from '../../components/AddressInput/AddressInput';
import PanelWindow from '../../components/PanelWindow/PanelWindow';
import { useRootStore } from '../../providers/RootStoreProvider';
import { useWindowManager } from '../../providers/WindowManager';
import { Coordinates, ManagedDetailWindowProps, UIStop } from '../../types';
import { useValidation, validateCoordinates, ValidationErrorData } from '../../utils/validation';
import { useErrorHandler, useSaveHandler, WindowState } from '../index';

function StopDetailWindow(props: ManagedDetailWindowProps) {
    const { t } = useTranslation();
    const { dataStore, mapStore, notificationStore } = useRootStore();
    const { removeWindow, updateWindowId } = useWindowManager();
    const { parseValidationError } = useValidation();
    const { commonErrorHandler } = useErrorHandler({
        entityType: 'stop',
        entityId: props.entityId,
    });

    const [windowState, setWindowState] = useState<WindowState>(WindowState.INIT);
    const [isEditingMap, setIsEditingMap] = useState(false);

    // Domain object state
    const [stopId, setStopId] = useState(props.entityId);
    const [name, setName] = useState<string>(
        props.isNew ? t('title.stop_new') : t('title.stop', { name: props.entityId })
    );
    const [description, setDescription] = useState('');
    const [lat, setLat] = useState<number | undefined>(undefined);
    const [lng, setLng] = useState<number | undefined>(undefined);
    const [coordinateString, setCoordinateString] = useState('');
    const [coordinatesAreValid, setCoordinatesAreValid] = useState(true);
    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [city, setCity] = useState('');
    const [alias, setAlias] = useState('');

    // We set window state to DIRTY from coordinate changes separately, buggy in effect dependencies.
    useEffect(() => {
        setWindowState(WindowState.DIRTY);
    }, [name, description]);

    const updateCoordinateString = (rawCoordinateVal: string) => {
        setCoordinateString(rawCoordinateVal);

        let parsedCoordinates: Coordinates | undefined = undefined;
        try {
            parsedCoordinates = JSON.parse(rawCoordinateVal);
        } catch (e) {
            if (!(e instanceof SyntaxError)) {
                console.error('Unexpected error when parsing JSON coordinates', e);
            }
        }

        const coordsAreValid = validateCoordinates(parsedCoordinates);
        setCoordinatesAreValid(coordsAreValid);

        if (parsedCoordinates && coordsAreValid) {
            const [_lng, _lat] = parsedCoordinates as Coordinates;

            setLat(_lat);
            setLng(_lng);
            setWindowState(WindowState.DIRTY);
            // Reset marker that was created when mouse hovered this window
            mapStore.clearAllByOwner(props.entityId);
            mapStore.addMarker(props.entityId, _lng, _lat);
        }
    };

    const stopToState = (stop: UIStop) => {
        setStopId(stop.id);
        setName(stop.name);
        setDescription(stop.description || '');
        setLng(stop.location[0]);
        setLat(stop.location[1]);
        setCoordinateString(JSON.stringify(stop.location));
        setStreet(stop.address?.street || '');
        setStreetNumber(stop.address?.streetNumber || '');
        setCity(stop.address?.city || '');
        setAlias(stop.address?.alias || '');
    };

    const stateToStop = (): UIStop | ValidationErrorData[] => {
        const errors: ValidationErrorData[] = [];
        const notSet = true;

        if (!stopId) {
            errors.push({ field: t('general.id'), notSet });
        }
        if (!name) {
            errors.push({ field: t('general.name'), notSet });
        }
        if (!lat && !lng) {
            errors.push({ field: t('general.coordinates'), notSet });
        }

        if (errors.length > 0) {
            return errors;
        }

        return {
            id: stopId!,
            name,
            description,
            location: [lng!, lat!],
            address: { street, streetNumber, city, alias, lng: lng!, lat: lat! },
        };
    };

    // Init & Cleanup
    useEffect(() => {
        (async () => {
            setWindowState(WindowState.UPDATING);
            try {
                if (!props.isNew) {
                    const stop = await dataStore.getStop(props.entityId);
                    stopToState(stop);
                    setWindowState(WindowState.IDLE);
                } else if (props.isNew) {
                    if (props.custom?.copyFromId) {
                        const stop = await dataStore.getStop(props.custom.copyFromId);
                        stopToState(stop);
                        setName(t('general.copyOf', { name: stop.name }));
                    }

                    setWindowState(WindowState.DIRTY);
                }
            } catch (err) {
                commonErrorHandler(err);
                removeWindow('details', props.windowId);
            }
        })();

        return () => {
            mapStore.disableEditing(props.entityId);
            mapStore.clearAllByOwner(props.entityId);
        };
    }, []);

    const startCoordinateSelection = () => {
        const editAllowed = mapStore.startEditingMarker(props.entityId, (latLng) => {
            if (latLng) {
                setLng(latLng.lng);
                setLat(latLng.lat);
                setCoordinateString(JSON.stringify([latLng.lng, latLng.lat]));
                setWindowState(WindowState.DIRTY);
            }
            setIsEditingMap(false);
        });
        // Set isEditingMap to true only if no other component, or this one, is editing the map
        if (!isEditingMap && editAllowed) {
            setIsEditingMap(true);
        }
    };

    const onSave = useSaveHandler(
        windowState,
        setWindowState,
        !props.isNew,
        async () => {
            const stop = stateToStop();
            if (Array.isArray(stop)) {
                throw parseValidationError(stop);
            }
            if (!props.isNew) {
                await dataStore.updateStop(stop);
            } else {
                const created = await dataStore.createStop(stop);
                updateWindowId(props.windowId, created.id);
            }
        },
        async (err) => {
            commonErrorHandler(err, {
                status: {
                    409: () => {
                        if (err.response?.data?.message === 'Future routes have trips') {
                            notificationStore.addNotification({
                                level: 'error',
                                text: t(
                                    'notification.error.updateConflict.tripsMustBeUnscheduled',
                                    {
                                        tripIds: err.response?.data?.context.tripIds,
                                    }
                                ),
                                autoConfirm: false,
                            });
                            return true;
                        } else {
                            return false;
                        }
                    },
                },
            });
        }
    );

    return (
        <PanelWindow
            title={name}
            panelType={'details'}
            windowId={props.windowId}
            windowState={windowState}
            onSave={onSave}
            onMouseEnter={() => lng && lat && mapStore.addMarker(props.entityId, lng, lat)}
            // Avoid clearing map markers if user is editing, so that they have a reference of the previous coordinates
            onMouseLeave={() => !isEditingMap && mapStore.clearAllByOwner(props.entityId)}
        >
            <div>
                <Form.Group>
                    <Form.Label>{t('general.name')}</Form.Label>
                    <Form.Control value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('general.description')}</Form.Label>
                    <Form.Control
                        value={description || ''}
                        onChange={(e) => setDescription(e.target.value)}
                        as={'textarea'}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('general.address')}</Form.Label>
                    <AddressInput
                        value={{ street, streetNumber, city, alias, lng: lng!, lat: lat! }}
                        onChange={(selectedAddress) => {
                            if (selectedAddress) {
                                setStreet(selectedAddress.street || '');
                                setStreetNumber(selectedAddress.streetNumber || '');
                                setCity(selectedAddress.city || '');
                                setAlias(selectedAddress.alias || '');
                                setLat(selectedAddress.lat);
                                setLng(selectedAddress.lng);
                                setWindowState(WindowState.DIRTY);
                            }
                        }}
                    />
                </Form.Group>
                <Form.Row className={'mb-4'}>
                    <Form.Group as={Col} className={'col-4'}>
                        <Form.Label>{t('form.stop.alias')}</Form.Label>
                        <Form.Control
                            placeholder={t('form.stop.alias')}
                            value={alias}
                            onChange={(e) => setAlias(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group as={Col} className={'col-3'}>
                        <Form.Label>{t('form.stop.street')}</Form.Label>
                        <Form.Control
                            placeholder={t('form.stop.street')}
                            value={street}
                            onChange={(e) => setStreet(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group as={Col} className={'col-2'}>
                        <Form.Label>{t('form.stop.streetNumber')}</Form.Label>
                        <Form.Control
                            placeholder={t('form.stop.streetNumber')}
                            value={streetNumber}
                            onChange={(e) => setStreetNumber(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group as={Col} className={'col-3'}>
                        <Form.Label>{t('form.stop.city')}</Form.Label>
                        <Form.Control
                            placeholder={t('form.stop.city')}
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Group>
                    <Form.Label>{t('general.coordinates')}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            value={coordinateString}
                            onChange={(e) => updateCoordinateString(e.target.value)}
                            isInvalid={!coordinatesAreValid}
                        />
                        <InputGroup.Append>
                            <Button
                                variant={'success'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (mapStore.isOwnerFrozen(props.entityId, 'marker')) {
                                        mapStore.unfreezeOwner(props.entityId, 'marker');
                                    } else {
                                        mapStore.freezeOwner(props.entityId, 'marker');
                                    }
                                }}
                            >
                                <Fa.FaMapPin
                                    style={{
                                        color: mapStore.isOwnerFrozen(props.entityId, 'marker')
                                            ? '#FFFFFF'
                                            : '#b9b8b8',
                                    }}
                                />
                            </Button>
                            <Button onClick={startCoordinateSelection}>
                                <Fa.FaMapMarkerAlt />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
            </div>
        </PanelWindow>
    );
}

export default observer(StopDetailWindow);
