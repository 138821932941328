import { values } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import styles from './VinkaNotifications.module.css';

import config from '../../infra/config';
import { useRootStore } from '../../providers/RootStoreProvider';
import { NotificationLevel, INotification } from '../../types/notification';

function getColourFromLevel(level: NotificationLevel): string {
    switch (level) {
        case 'info':
            return styles.info;
        case 'warn':
            return styles.warn;
        case 'error':
            return styles.error;
    }
}

export interface VinkaNotificationProps extends INotification {
    onClose: () => void;
}

export function VinkaNotification(props: VinkaNotificationProps) {
    const { t } = useTranslation();
    const autoConfirmDuration = config.app.notificationDuration;

    useEffect(() => {
        if (props.autoConfirm) {
            setTimeout(() => {
                props.onClose();
            }, autoConfirmDuration * 1000);
        }
    }, []);

    return (
        <div
            className={[styles.notification, getColourFromLevel(props.level)].join(' ')}
            onClick={props.onClose}
        >
            {typeof props.text === 'string' && props.applyTranslation ? t(props.text) : props.text}
            {/*
                Copied from https://stackoverflow.com/a/15945825/13167212
             */}
            {props.autoConfirm && (
                <div className={styles.progressContainer}>
                    <span style={{ width: '100%' }}>
                        <span
                            className={styles.notificationProgressBar}
                            style={{ animationDuration: autoConfirmDuration + 's' }}
                        />
                    </span>
                </div>
            )}
        </div>
    );
}

export function VinkaNotificationsContainer() {
    const { notificationStore } = useRootStore();

    return (
        <Col md={'auto'} className={styles.notificationsContainer}>
            {values(notificationStore.notifications).map((notificationConfig) => (
                <VinkaNotification
                    key={notificationConfig.id}
                    onClose={() => notificationStore.removeNotification(notificationConfig.id)}
                    {...notificationConfig}
                />
            ))}
        </Col>
    );
}

export default observer(VinkaNotificationsContainer);
