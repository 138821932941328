import { PropsWithChildren } from 'react';
import { Table } from 'react-bootstrap';

import styles from './VinkaTable.module.css';

export interface VinkaTableProps {
    className?: string;
}

export default function VinkaTable({ children, className }: PropsWithChildren<VinkaTableProps>) {
    return (
        <Table
            variant={'dark'}
            responsive
            hover
            striped
            className={[className, styles.VinkaTable].join(' ')}
        >
            {children}
        </Table>
    );
}
