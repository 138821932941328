import dayjs from 'dayjs';
import { values } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button, FormCheck, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Fa from 'react-icons/fa';

import tableStyles from '../../components/VinkaTable/VinkaTable.module.css';

import PanelWindow from '../../components/PanelWindow/PanelWindow';
import VinkaTable from '../../components/VinkaTable/VinkaTable';
import { useModal } from '../../providers/ModalManager';
import { useRootStore } from '../../providers/RootStoreProvider';
import { useWindowManager } from '../../providers/WindowManager';
import { ManagedWindowProps, MappedService } from '../../types';
import { getServiceValidity } from '../../utils';
import { useErrorHandler, WindowState } from '../index';

function ServicesListWindow(props: ManagedWindowProps) {
    const { t } = useTranslation();
    const { dataStore, notificationStore } = useRootStore();
    const { addWindow } = useWindowManager();
    const { addModal } = useModal();
    const { commonErrorHandler } = useErrorHandler({ entityType: 'service' });

    const [windowState] = useState<WindowState>(WindowState.IDLE);

    // Filter input for "name" column
    const [nameFilter, setNameFilter] = useState('');
    const [displayArchived, setDisplayArchived] = useState(false);

    // Set services from datastore
    useEffect(() => {
        // Just make the API request to get all services (and their timetables, stops and areas).
        // We'll do nothing with the return value here.
        dataStore.getServices().catch((err) => commonErrorHandler(err));
    }, []);

    const getDisplayableValidity = (service: MappedService): string => {
        const validTo = getServiceValidity(service);

        if (!validTo) {
            return t('table.service.validIndefinitely');
        } else if (validTo.unix() < dayjs().unix()) {
            return t('table.service.validityEnded', { date: validTo.format('L') });
        } else {
            return t('table.service.validUntil', { date: validTo.format('L') });
        }
    };

    const onArchiveClick = (service: MappedService) => {
        addModal({
            title: t('modal.archiveConfirm.service.title'),
            body: t('modal.archiveConfirm.service.body', {
                name: service.name,
            }),
            confirmText: t('modal.archiveConfirm.service.confirm'),
            onConfirm: () => archiveService(service),
            onCancel: true,
            confirmVariant: 'danger',
        });
    };

    const archiveService = async (service: MappedService) => {
        try {
            await dataStore.deleteService(service.id);
        } catch (e) {
            commonErrorHandler(e, {
                status: {
                    409: () => {
                        if (e.response?.data?.message === 'Used by active timetables') {
                            notificationStore.addNotification({
                                level: 'error',
                                text: t(
                                    'notification.error.archiveConflict.serviceHasActiveTimetables'
                                ),
                                autoConfirm: true,
                            });
                            return true;
                        } else {
                            return false;
                        }
                    },
                },
                entityId: service.id,
            });
        }
    };

    return (
        <PanelWindow
            title={t('title.service', { count: 0 })}
            panelType={'lists'}
            windowId={props.windowId}
            windowState={windowState}
        >
            <div>
                <FormControl
                    placeholder={t('general.filter')}
                    onChange={(e) => setNameFilter(e.target.value.toLowerCase())}
                />
                <VinkaTable>
                    <thead>
                        <tr>
                            <td style={{ border: 'none' }}>
                                <FormCheck
                                    inline
                                    type={'checkbox'}
                                    label={t('general.displayArchived')}
                                    checked={displayArchived}
                                    onChange={() => setDisplayArchived(!displayArchived)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>#</th>
                            <th>{t('general.name')}</th>
                            <th>{t('general.validity')}</th>
                            <th>{/* Column for action buttons */}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {values(dataStore.services)
                            .filter(
                                (service) =>
                                    service.name.toLowerCase().includes(nameFilter) &&
                                    // If not displaying archived, then only those with null deletedAt property
                                    (displayArchived || !service.deletedAt)
                            )
                            .map((service) => (
                                <tr
                                    key={'servicelist' + service.id}
                                    className={tableStyles.tableLink}
                                    onClick={() =>
                                        addWindow({
                                            entityId: '' + service.id,
                                            entityType: 'service',
                                            panelType: 'details',
                                        })
                                    }
                                >
                                    <td>{service.id}</td>
                                    <td>{service.name}</td>
                                    <td>{getDisplayableValidity(service)}</td>
                                    <td className={tableStyles.actionContainer}>
                                        <Fa.FaCopy
                                            className={tableStyles.actionIcon}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                dataStore
                                                    .copyService(
                                                        service.id,
                                                        t('general.copyOf', { name: service.name })
                                                    )
                                                    .then((copy) =>
                                                        addWindow({
                                                            entityId: '' + copy.id,
                                                            entityType: 'service',
                                                            panelType: 'details',
                                                        })
                                                    );
                                            }}
                                        />
                                        <Fa.FaTrashAlt
                                            className={[
                                                tableStyles.actionIcon,
                                                tableStyles.deleteActionIcon,
                                            ].join(' ')}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onArchiveClick(service);
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </VinkaTable>
                <Button
                    style={{ marginTop: '1em' }}
                    onClick={() =>
                        addWindow({
                            isNew: true,
                            panelType: 'details',
                            entityType: 'service',
                        })
                    }
                >
                    {t('button.createService')}
                </Button>
            </div>
        </PanelWindow>
    );
}

export default observer(ServicesListWindow);
