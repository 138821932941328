import { default as dayjs, Dayjs } from 'dayjs';
import * as _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Fa from 'react-icons/fa';
import AsyncSelect from 'react-select/async';

import tableStyles from '../../components/VinkaTable/VinkaTable.module.css';

import PanelWindow from '../../components/PanelWindow/PanelWindow';
import TimetableStopForm, {
    TimetableStopFormProps,
} from '../../components/TimetableStopForm/TimetableStopForm';
import TimetableValidation from '../../components/TimetableValidation/TimetableValidation';
import DatePicker from '../../components/VinkaDatePicker/VinkaDatePicker';
import VinkaTable from '../../components/VinkaTable/VinkaTable';
import config from '../../infra/config';
import { useRootStore } from '../../providers/RootStoreProvider';
import { useWindowManager } from '../../providers/WindowManager';
import {
    ManagedDetailWindowProps,
    ScheduledStop,
    StopType,
    TimetableOmission,
    TypedScheduledStop,
    UIArea,
    UIStop,
    UITimetable,
    WeekdayRecurrence,
} from '../../types';
import {
    equalByPaths,
    removeByIndex,
    selectStyles,
    setTimeStringToDate,
    weekdays,
} from '../../utils';
import { useValidation, ValidationErrorData } from '../../utils/validation';
import { useErrorHandler, useSaveHandler, WindowState } from '../index';

// This exists as a separate component, because FormCheck wouldn't render the
// updated checked status when a property of a WeekdayRecurrence object was passed
// as a property, so as a fix, additional state was added.
function WeekdayCheckbox(props: {
    checked: boolean | undefined;
    day: keyof WeekdayRecurrence;
    onChange: (checked: boolean) => void;
}) {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(!!props.checked);

    // Set state when props change
    useEffect(() => {
        setChecked(!!props.checked);
    }, [props.checked]);

    return (
        <Form.Check
            inline
            id={`day-${props.day}`}
            type={'checkbox'}
            label={t(`form.timetable.weekday_short.${props.day}`)}
            checked={checked}
            onChange={() => {
                props.onChange(!checked);
                setChecked(!checked);
            }}
        />
    );
}

// Helper for formatting omitted dates, which can be a single date or range between two dates
const formatOmittedDate = ({ from, to }: TimetableOmission<Dayjs>) => {
    if (to) {
        return `${from.format('L')}-${to.format('L')}`;
    } else {
        return from.format('L');
    }
};

const getStopLesserTime = (stop: ScheduledStop<any>) =>
    stop.departEarliest < stop.arriveLatest ? stop.departEarliest : stop.arriveLatest;

const sortTimetableStops = (stops: TypedScheduledStop[]): TypedScheduledStop[] => {
    return stops
        .sort((stopA, stopB) => {
            // Get the lesser of arrive/depart times, as depart may occur earlier than arrive.
            const lesserTimeA = getStopLesserTime(stopA);
            const lesserTimeB = getStopLesserTime(stopB);

            // First try sorting by arrive/depart times
            if (lesserTimeA < lesserTimeB) {
                return -1;
            } else if (lesserTimeA > lesserTimeB) {
                return 1;
            }
            // If times are equal, areas come before stops
            else if (stopA.type === 'area' && stopB.type === 'stop') {
                return -1;
            } else if (stopB.type === 'area' && stopA.type === 'stop') {
                return 1;
            }
            // Times are equal, and both stops are either areas or stops.
            else {
                return 0;
            }
        })
        .map((stop, index) => Object.assign(stop, { ordinal: index }));
};

export function TimetableDetailWindow(props: ManagedDetailWindowProps) {
    const { t } = useTranslation();
    const { dataStore, mapStore, notificationStore } = useRootStore();
    const { removeWindow, updateWindowId } = useWindowManager();
    // Ref to date picker for adding omission, used in order to clear the input after "submitting"
    const omissionDatePicker = useRef(null);
    const { commonErrorHandler } = useErrorHandler({
        entityType: 'timetable',
        entityId: props.entityId,
    });
    const { parseValidationError } = useValidation();

    const [windowState, setWindowState] = useState<WindowState>(WindowState.INIT);

    // Entity state
    const [timetableId, setTimetableId] = useState<string>(props.entityId);
    const [name, setName] = useState<string>(
        props.isNew ? t('title.timetable_new') : t('title.timetable', { name: props.entityId })
    );
    const [description, setDescription] = useState<string>('');
    const [validFrom, setValidFrom] = useState<Dayjs | undefined>(undefined);
    const [validTo, setValidTo] = useState<Dayjs | undefined>(undefined);
    const [validIndefinitely, setValidIndefinitely] = useState<boolean>(false);
    const [recurrence, setRecurrence] = useState<WeekdayRecurrence>({});
    const [omissions, setOmissions] = useState<TimetableOmission[]>([]);
    const [newOmission, setNewOmission] = useState<TimetableOmission | undefined>(undefined);
    // Stops and areas combined to one array.
    const [combinedStops, setCombinedStops] = useState<TypedScheduledStop[]>([]);
    // Separate array of combined stops, but sorted. Exists in separate state so we can modify combinedStops,
    // and react to changes in that array, and sort the stops in an effect without causing and infinite loop.
    const [sortedCombinedStops, setSortedCombinedStops] = useState<TypedScheduledStop[]>([]);
    const [vehicle, setVehicle] = useState<number | undefined>(undefined);
    const [serviceId, setServiceId] = useState<string | undefined>(props.custom?.serviceId);
    const [timezone, setTimezone] = useState<string>(config.localization.timezone);

    // Stop to update in stop form
    const [updatableStop, setUpdatableStop] = useState<TypedScheduledStop | null>(null);
    // The date for route created from the timetable. Used for manual testing purposes.
    const [routeCreationDate, setRouteCreationDate] = useState<Dayjs | undefined>(undefined);
    const [creatingRoute, setCreatingRoute] = useState(false);

    // Set window state to dirty on state change
    useEffect(() => setWindowState(WindowState.DIRTY), [
        name,
        description,
        validFrom,
        validTo,
        recurrence,
        omissions,
        newOmission,
        combinedStops,
        vehicle,
    ]);

    // Mount
    useEffect(() => {
        (async () => {
            setWindowState(WindowState.UPDATING);
            try {
                if (!timetableId) {
                    throw Error('Cannot create detail window for a timetable that has no id');
                } else if (!props.isNew) {
                    const timetable = await dataStore.getTimetable(props.entityId);
                    timetableToState(timetable);
                    setWindowState(WindowState.IDLE);
                } else if (props.isNew) {
                    if (props.custom?.copyFromId) {
                        const timetable = await dataStore.getTimetable(props.custom.copyFromId);
                        timetableToState(timetable);
                        // This is somewhat unnecessary, but precautionary, as DataStore is expected to delete the id
                        // when we save this new timetable with copied data.
                        setTimetableId(props.entityId);
                        setName(t('general.copyOf', { name: timetable.name }));

                        // Set default start date for timetable to today if original start date is in the past
                        const now = dayjs();
                        if (timetable.validity.from.isBefore(now)) {
                            setValidFrom(now.startOf('day'));

                            if (timetable.validity.to && now.isAfter(timetable.validity.to)) {
                                setValidTo(now.endOf('day'));
                            }
                        }
                    } else if (!props.custom?.serviceId) {
                        throw Error('New timetable must receive serviceId as custom prop');
                    }
                    setWindowState(WindowState.DIRTY);
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Error when creating detail view', e);
                commonErrorHandler(e);
                removeWindow('details', props.windowId);
            }
        })();
    }, []);

    useEffect(() => {
        // Cannot set with setCombinedStops here, because combinedStops is an effect dependency, so
        // that would cause an infinite loop for this effect.
        setSortedCombinedStops(sortTimetableStops(combinedStops));
    }, [combinedStops]);

    // The server should instruct the browser to cache these requests via the Cache-Control header
    const loadVehicleOptions: typeof AsyncSelect.defaultProps.loadOptions = async () => {
        const vehicles = await dataStore.getVehicles();
        return vehicles.map((v) => ({
            value: v.number,
            label: `${v.number} - ${v.name}`,
        }));
    };

    const itemTypeLocalized = (type: StopType) => {
        switch (type) {
            case 'stop':
                return t('title.stop');
            case 'area':
                return t('title.area');
            default:
                return '?';
        }
    };

    const timetableToState = (timetable: UITimetable) => {
        setTimetableId(timetable.id!);
        setName(timetable.name);
        setDescription(timetable.description || '');
        setValidFrom(timetable.validity.from);
        setValidTo(timetable.validity.to);
        setValidIndefinitely(!timetable.validity.to);
        setRecurrence(timetable.recurrence);
        setOmissions(timetable.exceptions?.omit || []);
        setCombinedStops([
            // Assign "type" to stops and areas so we can clearly identify the type when they're in the same array
            ...timetable.areas.map((area) => ({ ...area, type: 'area' as StopType })),
            ...timetable.stops.map((stop) => ({ ...stop, type: 'stop' as StopType })),
        ]);
        setVehicle(timetable.vehicle);
        setServiceId(timetable.serviceId);
        setTimezone(timetable.timezone);
    };

    const stateToTimetable = (): [UITimetable | undefined, ValidationErrorData[]] => {
        const errors: ValidationErrorData[] = [];
        const notSet = true;

        if (!timetableId) {
            errors.push({ field: t('general.id'), notSet });
        }
        if (!name) {
            errors.push({ field: t('general.name'), notSet });
        }
        // validFrom required, validTo is not.
        if (!validFrom) {
            errors.push({ field: t('general.validity'), notSet });
        }
        if (!vehicle) {
            errors.push({ field: t('general.vehicle'), notSet });
        }
        if (!serviceId) {
            errors.push({ field: t('form.timetable.serviceId'), notSet });
        }
        if (sortedCombinedStops.length === 0) {
            errors.push({ message: t('validation.routePlanEmpty') });
        }

        if (errors.length > 0) {
            return [undefined, errors];
        }

        const earliestTime = getStopLesserTime(sortedCombinedStops[0]);
        // Set the start time of the timetable to the validity beginning of the timetable, so that
        // the correct day is used when converted to a UTC YYYYMMDD string for engine
        const adjustedValidFrom = setTimeStringToDate(validFrom!, earliestTime, timezone);

        const groupedStops = _.groupBy(sortedCombinedStops, 'type');

        return [
            {
                id: timetableId!,
                name,
                description,
                recurrence,
                vehicle: vehicle!,
                serviceId: serviceId!,
                validity: { from: adjustedValidFrom, to: validTo },
                exceptions: { omit: omissions },
                areas: ((groupedStops.area || []).map((area) =>
                    _.omit(area, 'type')
                ) as unknown) as ScheduledStop<string, Dayjs, UIArea>[],
                stops: ((groupedStops.stop || []).map((stop) =>
                    _.omit(stop, 'type')
                ) as unknown) as ScheduledStop<string, Dayjs, UIStop>[],
                timezone,
            },
            [],
        ];
    };

    const onStopUpdated: TimetableStopFormProps['onChange'] = (oldStop, newStop) => {
        // No old stop => the stop is completely new.
        if (oldStop === null) {
            setCombinedStops([...combinedStops, newStop]);
        }
        // Has old stop => an old stop was modified.
        else {
            // Find the index of the old stop.
            const stopIndex = combinedStops.findIndex((combinedStop) =>
                equalByPaths(oldStop, combinedStop, [
                    'type',
                    'arriveLatest',
                    'departEarliest',
                    'location.id',
                ])
            );
            if (stopIndex !== -1) {
                setCombinedStops([
                    ...combinedStops.slice(0, stopIndex),
                    newStop,
                    ...combinedStops.slice(stopIndex + 1),
                ]);
            }
            // This can happen if the stop is deleted after editing was started.
            else {
                notificationStore.addNotification({
                    level: 'warn',
                    text: t('notification.warn.stopEditFailed'),
                });
            }
        }
        setUpdatableStop(null);
    };

    const onRouteCreate = async () => {
        // Consider timetable start time for route startDate, so it's created for the right date
        // after conversion to utc YYYYMMDD timestamp in ES
        const startDate = setTimeStringToDate(
            routeCreationDate!,
            sortedCombinedStops[0].arriveLatest,
            timezone
        );
        setCreatingRoute(true);
        try {
            const route = await dataStore.createRoute(timetableId, startDate);
            notificationStore.addNotification({
                text: t('notification.info.routeCreateSuccess', { id: route.id }),
                level: 'info',
            });
        } catch (e) {
            notificationStore.addNotification({
                text: t('notification.error.routeCreateFailed', { message: e.message }),
                level: 'error',
            });
        } finally {
            setCreatingRoute(false);
        }
    };

    const onSave = useSaveHandler(
        windowState,
        setWindowState,
        !props.isNew,
        async () => {
            const [timetable, errors] = stateToTimetable();

            if (!timetable) {
                throw parseValidationError(errors);
            }

            if (!props.isNew) {
                await dataStore.updateTimetable(timetable);
            } else {
                const newTimetable = await dataStore.createTimetable(timetable);
                // This essentially recreates the component in place
                updateWindowId(props.entityId!, newTimetable.id);
            }
        },
        async (err) => {
            commonErrorHandler(err, {
                status: {
                    409: () => {
                        if (err.response?.data?.message === 'Future routes have trips') {
                            notificationStore.addNotification({
                                level: 'error',
                                text: t(
                                    'notification.error.updateConflict.tripsMustBeUnscheduled',
                                    {
                                        tripIds: err.response?.data?.context.tripIds,
                                    }
                                ),
                                autoConfirm: false,
                            });
                            return true;
                        } else {
                            return false;
                        }
                    },
                },
            });
        }
    );

    return (
        <PanelWindow
            title={name}
            panelType={'details'}
            windowId={props.windowId}
            windowState={windowState}
            onSave={onSave}
        >
            <div>
                <Form.Group>
                    <Form.Label>{t('general.name')}</Form.Label>
                    <Form.Control value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('general.description')}</Form.Label>
                    <Form.Control
                        value={description || ''}
                        onChange={(e) => setDescription(e.target.value)}
                        as={'textarea'}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('general.validity')}</Form.Label>
                    <div>
                        <Form.Check
                            inline
                            type={'checkbox'}
                            label={t('form.timetable.validIndefinitely')}
                            checked={validIndefinitely}
                            onChange={() => {
                                setValidIndefinitely(!validIndefinitely);
                                setValidTo(undefined);
                            }}
                        />
                    </div>
                    {validIndefinitely ? (
                        <DatePicker
                            defaultValue={validFrom}
                            pickerType={'single'}
                            onChange={([from]: Dayjs[]) => {
                                setValidFrom(from);
                                setValidTo(undefined);
                            }}
                        />
                    ) : (
                        <DatePicker
                            defaultValue={validFrom && validTo ? [validFrom, validTo] : undefined}
                            pickerType={'range'}
                            onChange={([from, to]: Dayjs[]) => {
                                setValidFrom(from);
                                setValidTo(to);
                            }}
                        />
                    )}
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('form.timetable.recurrence')}</Form.Label>
                    <div>
                        {weekdays.map((day) => (
                            <WeekdayCheckbox
                                day={day}
                                onChange={(checked) =>
                                    setRecurrence(Object.assign({}, recurrence, { [day]: checked }))
                                }
                                checked={recurrence[day]}
                                key={`tt-${props.entityId}-day-${day}`}
                            />
                        ))}
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('form.timetable.omittedDates')}</Form.Label>
                    <VinkaTable>
                        <tbody>
                            {omissions.map((omission, index) => (
                                <tr key={`tt-${props.entityId}-omission-${index}`}>
                                    <td>{formatOmittedDate(omission)}</td>
                                    <td className={tableStyles.actionContainer}>
                                        {/* Removes omission from array of timetable.exceptions.omit array */}
                                        <Fa.FaTrashAlt
                                            className={tableStyles.actionIcon}
                                            onClick={() =>
                                                setOmissions(omissions.filter(removeByIndex(index)))
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </VinkaTable>
                    <InputGroup>
                        <InputGroup.Prepend>
                            {/* Add omission from datepicker value, and clear datepicker */}
                            <Button
                                disabled={newOmission?.from === undefined}
                                onClick={() => {
                                    if (newOmission !== undefined) {
                                        setOmissions(
                                            [...omissions, newOmission].sort(
                                                (a, b) => a.from.unix() - b.from.unix()
                                            )
                                        );
                                        setNewOmission(undefined);
                                        (omissionDatePicker.current as any).flatpickr.clear();
                                    }
                                }}
                            >
                                {t('form.timetable.addOmission')}
                            </Button>
                        </InputGroup.Prepend>
                        {/* Pick date(s) for omission */}
                        <DatePicker
                            pickerType={'range'}
                            onChange={([from, to]: Dayjs[]) => {
                                if (from && !to) {
                                    to = from.endOf('day');
                                }

                                setNewOmission({ from, to });
                            }}
                            ref={omissionDatePicker}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('general.vehicle')}</Form.Label>
                    <AsyncSelect
                        // We do don't store the vehicle name, so the value of the label of the value will
                        // not correspond to the labels of the available options
                        value={{ label: '' + (vehicle || '') } as any}
                        styles={selectStyles}
                        maxMenuHeight={200}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        loadOptions={loadVehicleOptions}
                        filterOption={(option, rawInput) => {
                            return option.label.toLowerCase().includes(rawInput.toLowerCase());
                        }}
                        noOptionsMessage={() => t('general.noResults')}
                        onChange={(option) => {
                            if (option && option.value) {
                                setVehicle((option.value as unknown) as number);
                            }
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('form.timetable.routePlan')}</Form.Label>
                    <VinkaTable>
                        <thead>
                            <tr>
                                <th>{t('form.timetable.itemType')}</th>
                                <th>{t('form.timetable.timetableItem')}</th>
                                <th>{t('form.timetable.arriveLatest')}</th>
                                <th>{t('form.timetable.departEarliest')}</th>
                                <th>{t('form.timetable.flexible')}</th>
                                <th>{/* Column for action buttons */}</th>
                            </tr>
                        </thead>
                        <tbody
                            onMouseEnter={() =>
                                combinedStops.forEach((stop) => {
                                    if (stop.type === 'stop') {
                                        const _stop = stop.location as UIStop;
                                        mapStore.addMarker(props.entityId, ..._stop.location);
                                    } else if (stop.type === 'area') {
                                        const _area = stop.location as UIArea;
                                        mapStore.addPolygon(props.entityId, _area.polygon);
                                    }
                                })
                            }
                            onMouseLeave={() => mapStore.clearAllByOwner(props.entityId)}
                        >
                            {sortedCombinedStops.map((stop, index) => (
                                <tr
                                    key={`tt-${props.entityId}-stop-${index}`}
                                    // Sets this stop to the stop form
                                    onClick={() => setUpdatableStop(sortedCombinedStops[index])}
                                    onMouseEnter={() => {
                                        if (stop.type === 'stop') {
                                            mapStore.setHighlightedMarker(
                                                (stop.location as UIStop).location
                                            );
                                        } else if (stop.type === 'area') {
                                            mapStore.setHighlightedPolygon(
                                                (stop.location as UIArea).polygon
                                            );
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        if (stop.type === 'stop') {
                                            mapStore.setHighlightedMarker(null);
                                        } else if (stop.type === 'area') {
                                            mapStore.setHighlightedPolygon(null);
                                        }
                                    }}
                                    className={tableStyles.tableLink}
                                >
                                    <td>{itemTypeLocalized(stop.type)}</td>
                                    <td>{stop.location.name}</td>
                                    <td>{stop.arriveLatest}</td>
                                    <td>{stop.departEarliest}</td>
                                    <td>{stop.isFlexible && <Fa.FaCheck />}</td>
                                    <td className={tableStyles.actionContainer}>
                                        {/* Removes the stop */}
                                        <Fa.FaTrashAlt
                                            className={[
                                                tableStyles.actionIcon,
                                                tableStyles.deleteActionIcon,
                                            ].join(' ')}
                                            onClick={() =>
                                                setCombinedStops(
                                                    combinedStops.filter(removeByIndex(index))
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </VinkaTable>
                </Form.Group>
                <Form.Group>
                    <TimetableStopForm onChange={onStopUpdated} stop={updatableStop} />
                </Form.Group>
                {/* Button for creating a route from the timetable. Not intended for production use */}
                {config.app.showTimetableRouting && (
                    <Form.Group>
                        <Form.Label>{t('form.timetable.createRoute')}</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <Button
                                    variant={'warning'}
                                    disabled={routeCreationDate === undefined || props.isNew}
                                    onClick={onRouteCreate}
                                >
                                    {creatingRoute ? (
                                        <Fa.FaSpinner className={'vinkaSpinner'} />
                                    ) : (
                                        t(
                                            routeCreationDate
                                                ? 'form.timetable.createRoute'
                                                : 'form.timetable.selectDate'
                                        )
                                    )}
                                </Button>
                            </InputGroup.Prepend>
                            <DatePicker
                                onChange={([date]) => setRouteCreationDate(date)}
                                pickerType={'single'}
                            />
                        </InputGroup>
                    </Form.Group>
                )}
                <Form.Group>
                    <Form.Label>{t('form.timetable.validateTimetable')}</Form.Label>
                    <TimetableValidation timetableId={props.entityId} parentState={windowState} />
                </Form.Group>
            </div>
        </PanelWindow>
    );
}

export default observer(TimetableDetailWindow);
