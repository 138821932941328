import { createContext } from 'react';

import { WindowManagerState } from '../types';

/**
 * The Context for WindowManager.
 */
const context = createContext<WindowManagerState>({
    details: [],
    utils: [],
    lists: [],
    addWindow: () => {
        return;
    },
    removeWindow: () => {
        return;
    },
    updateWindowId: () => {
        return;
    },
});

export default context;
