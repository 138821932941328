import EnvUtils, { EnvUtilsOptions } from '@vinka/env-utils';

import { Coordinates } from '../types';

const envUtilsOptions: EnvUtilsOptions = {
    prefix: 'REACT_APP_FLEX_',
};

// In production build, we get the env values from window. Consult README for more info.
if (process.env.NODE_ENV === 'production') {
    const envObject = (window as any)._env_;

    if (!envObject) {
        throw Error('Environment variables have not been configured to window in production env');
    }

    envUtilsOptions.envObject = envObject;
}

const env = new EnvUtils(envUtilsOptions);

let rootUrl = '';
const [urlApp, urlEnv] = window.location.hostname.split('.');
if (urlApp === 'localhost') {
    rootUrl = 'http://localhost:3010/flex';
} else if (urlApp === 'flex') {
    rootUrl = `https://flex.${urlEnv}.vinka.cloud`;
} else {
    rootUrl = `https://${urlApp}.vinka.cloud/flex`;
}

const config = Object.freeze({
    localization: {
        locale: env.getValue<'en' | 'fi'>({
            name: 'LOCALE',
            defaults: {
                '*': 'fi',
            },
        }),
        timezone: env.getValue<string>({
            name: 'TIMEZONE',
            defaults: {
                '*': 'Europe/Helsinki',
            },
        }),
    },
    auth0: {
        domain: env.getValue<string>({
            name: 'AUTH0_DOMAIN',
            defaults: {
                'production,development': 'vinka.eu.auth0.com',
            },
        }),
        clientId: env.getValue<string>({
            name: 'AUTH0_CLIENT_ID',
            defaults: {
                'production,development': 'DQKPCvUQzucdBznTkf7KIGA5gTQS5JYR',
            },
        }),
        audience: env.getValue<string>({
            name: 'AUTH0_AUDIENCE',
            defaults: {
                '*': 'https://flex-api.vinka.cloud',
            },
        }),
    },
    api: {
        url: env.getValue<string>({
            name: 'API_URL',
            defaults: {
                development: 'http://localhost:5010',
            },
        }),
    },
    app: {
        notificationDuration: env.getValue<number>({
            name: 'APP_NOTIFICATION_DURATION',
            translator: 'integer',
            defaults: {
                '*': 5,
            },
        }),
        // Set env variable with "lat,lng", comes out as [lat, lng], even though in flex we generally
        // set coordinates as [lng,lat] to conform with GeoJSON
        mapCenter: env.getValue<Coordinates>({
            name: 'APP_MAP_CENTER',
            translator: (envVar) => {
                const [latStr, lngStr] = envVar.split(',');
                const lat = parseFloat(latStr);
                const lng = parseFloat(lngStr);
                if (isNaN(lat) || isNaN(lng)) {
                    throw Error('Invalid map center: ' + envVar);
                }
                return [lat, lng];
            },
            defaults: {
                '*': [61.4834, 23.7621],
            },
        }),
        showTimetableRouting: env.getValue<boolean>({
            name: 'APP_SHOW_TIMETABLE_ROUTING',
            translator: 'boolean',
            defaults: {
                development: true,
                '*': false,
            },
        }),
        rootUrl,
    },
});

export default config;
