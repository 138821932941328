import { values } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button, FormCheck, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Fa from 'react-icons/fa';

import tableStyles from '../../components/VinkaTable/VinkaTable.module.css';

import PanelWindow from '../../components/PanelWindow/PanelWindow';
import VinkaTable from '../../components/VinkaTable/VinkaTable';
import { useModal } from '../../providers/ModalManager';
import { useRootStore } from '../../providers/RootStoreProvider';
import { useWindowManager } from '../../providers/WindowManager';
import { ManagedWindowProps, UIArea } from '../../types';
import { useErrorHandler, WindowState } from '../index';

function AreasListWindow(props: ManagedWindowProps) {
    const { t } = useTranslation();
    const { dataStore, notificationStore, mapStore } = useRootStore();
    const { commonErrorHandler } = useErrorHandler({ entityType: 'area' });
    const { addWindow } = useWindowManager();
    const { addModal } = useModal();

    const [windowState, setWindowState] = useState<WindowState>(WindowState.INIT);
    const [nameFilter, setNameFilter] = useState('');
    const [displayArchived, setDisplayArchived] = useState(false);

    useEffect(() => {
        (async () => {
            setWindowState(WindowState.UPDATING);
            try {
                await dataStore.getAreas();
            } catch (e) {
                commonErrorHandler(e);
            } finally {
                setWindowState(WindowState.IDLE);
            }
        })();
    }, []);

    const onArchiveClick = (area: UIArea) => {
        addModal({
            title: t('modal.archiveConfirm.area.title'),
            body: t('modal.archiveConfirm.area.body', {
                name: area.name,
            }),
            confirmText: t('modal.archiveConfirm.area.confirm'),
            onConfirm: () => archiveArea(area),
            onCancel: true,
            confirmVariant: 'danger',
        });
    };

    const archiveArea = async (area: UIArea) => {
        try {
            await dataStore.deleteArea(area.id);
        } catch (e) {
            commonErrorHandler(e, {
                status: {
                    409: () => {
                        if (e.response?.data?.message === 'Used by active timetables') {
                            notificationStore.addNotification({
                                level: 'error',
                                text: t('notification.error.archiveConflict.areaUsedByTimetable'),
                                autoConfirm: true,
                            });
                            return true;
                        } else {
                            return false;
                        }
                    },
                },
                entityId: area.id,
            });
        }
    };

    return (
        <PanelWindow
            title={t('title.area', { count: 0 })}
            panelType={'lists'}
            windowId={props.windowId}
            windowState={windowState}
        >
            <div>
                <FormControl
                    placeholder={t('general.filter')}
                    onChange={(e) => setNameFilter(e.target.value.toLowerCase())}
                />
                <VinkaTable>
                    <thead>
                        <tr>
                            <td style={{ border: 'none' }}>
                                <FormCheck
                                    inline
                                    type={'checkbox'}
                                    label={t('general.displayArchived')}
                                    checked={displayArchived}
                                    onChange={() => setDisplayArchived(!displayArchived)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>#</th>
                            <th>{t('general.name')}</th>
                            <th>{/* Column for action buttons */}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {values(dataStore.areas)
                            .filter(
                                (area) =>
                                    area.name.toLowerCase().includes(nameFilter) &&
                                    (displayArchived || !area.deletedAt)
                            )
                            .map((area) => (
                                <tr
                                    key={'arealist' + area.id}
                                    className={tableStyles.tableLink}
                                    onClick={() =>
                                        addWindow({
                                            panelType: 'details',
                                            entityType: 'area',
                                            entityId: area.id,
                                        })
                                    }
                                    onMouseEnter={() => mapStore.addPolygon(area.id, area.polygon)}
                                    onMouseLeave={() => mapStore.clearAllByOwner(area.id)}
                                >
                                    <td>{area.id}</td>
                                    <td>{area.name}</td>
                                    <td>
                                        <Fa.FaMapPin
                                            style={{ marginRight: '0.5em' }}
                                            className={[
                                                tableStyles.actionIcon,
                                                tableStyles.pinActionIcon,
                                                mapStore.isOwnerFrozen(area.id, 'polygon')
                                                    ? tableStyles.pinActive
                                                    : '',
                                            ].join(' ')}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (mapStore.isOwnerFrozen(area.id, 'polygon')) {
                                                    mapStore.unfreezeOwner(area.id, 'polygon');
                                                } else {
                                                    mapStore.freezeOwner(area.id, 'polygon');
                                                }
                                            }}
                                        />
                                        <Fa.FaCopy
                                            className={tableStyles.actionIcon}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                addWindow({
                                                    entityType: 'area',
                                                    panelType: 'details',
                                                    isNew: true,
                                                    custom: {
                                                        copyFromId: area.id,
                                                    },
                                                });
                                            }}
                                        />
                                        <Fa.FaTrashAlt
                                            className={[
                                                tableStyles.actionIcon,
                                                tableStyles.deleteActionIcon,
                                            ].join(' ')}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onArchiveClick(area);
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </VinkaTable>
                <Button
                    style={{ marginTop: '1em' }}
                    onClick={() =>
                        addWindow({
                            isNew: true,
                            panelType: 'details',
                            entityType: 'area',
                        })
                    }
                >
                    {t('button.createArea')}
                </Button>
            </div>
        </PanelWindow>
    );
}

export default observer(AreasListWindow);
