import { DataStore } from './DataStore';
import { MapStore } from './MapStore';
import { NotificationStore } from './NotificationStore';

export default class RootStore {
    public dataStore: DataStore;
    public notificationStore: NotificationStore;
    public mapStore: MapStore;

    constructor() {
        this.dataStore = new DataStore();
        this.notificationStore = new NotificationStore(this);
        this.mapStore = new MapStore(this);
    }
}
