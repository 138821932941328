import dayjs, { Dayjs } from 'dayjs';
import * as _ from 'lodash';

import {
    APIArea,
    APIService,
    APIStop,
    APITimetable,
    IService,
    ScheduledStop,
    UIArea,
    UIService,
    UIStop,
    UITimetable,
    UnidentifiedService,
} from '../types';

const translate = {
    service: {
        fromApi(apiService: APIService): UIService {
            return {
                ...apiService,
                id: '' + apiService.id,
                timetables: apiService.timetables.map((tt) => translate.timetable.fromApi(tt)),
                deletedAt: apiService.deletedAt ? dayjs(apiService.deletedAt) : undefined,
            };
        },
        toUnidentified<D>(service: IService<any, D>): UnidentifiedService<D> {
            const unidentified = (_.cloneDeep(service) as unknown) as UnidentifiedService<D>;
            delete unidentified.id;
            unidentified.timetables.forEach((tt) => {
                delete tt.id;
                delete tt.serviceId;
            });
            return unidentified;
        },
    },
    timetable: {
        fromApi(apiTimetable: APITimetable): UITimetable {
            const omit = apiTimetable.exceptions?.omit?.map((omission) => {
                const to = omission.to ? dayjs(omission.to) : undefined;
                return { from: dayjs(omission.from), to };
            });
            const exceptions = omit ? { omit } : {};

            return {
                ...apiTimetable,
                id: '' + apiTimetable.id,
                exceptions,
                validity: {
                    from: dayjs(apiTimetable.validity.from),
                    to: apiTimetable.validity.to ? dayjs(apiTimetable.validity.to) : undefined,
                },
                stops: apiTimetable.stops.map((stop) => translate.timetable.stop.fromApi(stop)),
                areas: apiTimetable.areas.map((area) => translate.timetable.area.fromApi(area)),
                serviceId: '' + apiTimetable.serviceId,
                deletedAt: apiTimetable.deletedAt ? dayjs(apiTimetable.deletedAt) : undefined,
            };
        },
        stop: {
            fromApi(
                apiTimetableStop: ScheduledStop<number, string, APIStop>
            ): ScheduledStop<string, Dayjs, UIStop> {
                return {
                    ...apiTimetableStop,
                    location: {
                        ...apiTimetableStop.location,
                        id: '' + apiTimetableStop.location.id,
                        deletedAt: undefined, // Archived state not relevant here
                    },
                    arriveLatest: apiTimetableStop.arriveLatest,
                    departEarliest: apiTimetableStop.departEarliest,
                };
            },
        },
        area: {
            fromApi(
                apiTimetableArea: ScheduledStop<number, string, APIArea>
            ): ScheduledStop<string, Dayjs, UIArea> {
                return {
                    ...apiTimetableArea,
                    location: {
                        ...apiTimetableArea.location,
                        id: '' + apiTimetableArea.location.id,
                        deletedAt: undefined, // Archived state not relevant here
                    },
                    arriveLatest: apiTimetableArea.arriveLatest,
                    departEarliest: apiTimetableArea.departEarliest,
                };
            },
        },
    },
    stop: {
        fromApi(apiStop: APIStop): UIStop {
            return {
                ...apiStop,
                id: '' + apiStop.id,
                deletedAt: apiStop.deletedAt ? dayjs(apiStop.deletedAt) : undefined,
            };
        },
    },
    area: {
        fromApi(apiArea: APIArea): UIArea {
            return {
                ...apiArea,
                id: '' + apiArea.id,
                deletedAt: apiArea.deletedAt ? dayjs(apiArea.deletedAt) : undefined,
            };
        },
    },
};

export default translate;
