import dayjs, { Dayjs, ConfigType as DateLike } from 'dayjs';
import * as _ from 'lodash';
import { values } from 'mobx';
import { NamedProps } from 'react-select';

import { CSSObject } from '@emotion/serialize';

import {
    ManagedWindowConfig,
    MappedService,
    WeekdayRecurrence,
    WindowEntityType,
    WindowPanelMap,
} from '../types';

/**
 * Helper for iterating all panel types.
 */
export const allPanels: readonly (keyof WindowPanelMap)[] = Object.freeze([
    'lists',
    'details',
    'utils',
]);

/**
 * Encodes a window configuration into a window id
 * @param config the window configuration
 */
export function encodeWindowId(config: ManagedWindowConfig): string {
    if (config.entityId) {
        return config.entityType + '-' + config.entityId;
    }
    if (config.panelType === 'lists') {
        return config.entityType;
    }
    if (config.isNew) {
        return config.entityType + '-new-' + Date.now();
    }

    throw Error(`Could not parse component id with config ${JSON.stringify(config)}`);
}

/**
 * Decodes a window id into a window configuration
 * @param panelType the panel type the window id is associated with
 * @param windowId the window id
 */
export function decodeWindowId(
    panelType: keyof WindowPanelMap,
    windowId: string
): ManagedWindowConfig {
    const [entityType, ...idValues] = windowId.split('-');
    let entityId: string | undefined;
    let isNew: true | undefined;

    if (idValues.length === 1) {
        entityId = idValues[0];
    } else if (idValues.length === 2 && idValues[0] === 'new') {
        isNew = true;
    }

    return {
        panelType: panelType,
        entityType: entityType as WindowEntityType,
        entityId,
        isNew,
    };
}

/**
 * Returns the total validity timespan of a service based on it's timetables.
 * @param service
 */
export function getServiceValidity(service: MappedService): Dayjs | undefined {
    let to: Dayjs | undefined;

    const toArray = values(service.timetables).map((timetable) => timetable.validity.to);
    if (toArray && !toArray.includes(undefined)) {
        // Dependent on dayjs MinMax plugin
        to = dayjs.max((toArray as unknown) as Dayjs);
    }

    return to;
}

export const weekdays: readonly (keyof WeekdayRecurrence)[] = Object.freeze([
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
    'sun',
]);

// Creates a filter predicate which removes item by index
export function removeByIndex<T>(index: number): (_value: T, _index: number) => boolean {
    return (_value, _index) => _index !== index;
}

export function equalByPaths<T>(a: T, b: T, paths: (keyof T | string)[]): boolean {
    for (const path of paths) {
        if (_.get(a, path) !== _.get(b, path)) {
            return false;
        }
    }

    return true;
}

// Styles for react-select. Cannot conveniently use css modules for this.
export const selectStyles: NamedProps['styles'] = {
    menuList: (base: CSSObject): CSSObject => ({
        ...base,
        fontSize: '0.9em',
        color: '#495057',
    }),
};

// Set time to a date from a HH:mm time string
export function setTimeStringToDate(date: DateLike, time: string, timezone: string) {
    const [hoursString, minutesString] = time.split(':');
    return dayjs(date)
        .tz(timezone)
        .set('hour', +hoursString)
        .set('minute', +minutesString);
}
