import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { Button, InputGroup, Overlay, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Fa from 'react-icons/fa';

import { useRootStore } from '../../providers/RootStoreProvider';
import { useErrorHandler, WindowState } from '../../windows';
import VinkaDatePicker from '../VinkaDatePicker/VinkaDatePicker';

export interface TimetableValidationProps {
    // The id of the timetable to validate.
    timetableId: string;
    // The window state of the parent is used to check that changes are saved before validation.
    parentState: WindowState;
}

const addDays = 7;

/**
 * A form for validating a timetable. The backend validates the timetable by generating hypo routes
 * from the timetable for a timespan. This component also displays the results
 */
export default observer(function TimetableValidation(props: TimetableValidationProps) {
    const { dataStore, notificationStore } = useRootStore();
    const { t } = useTranslation();
    const { commonErrorHandler } = useErrorHandler({
        entityId: props.timetableId,
        entityType: 'timetable',
    });

    // The start date for the hypo routes to generate for validation
    const [startDate, setStartDate] = useState<Dayjs>(dayjs());
    // The end date for the hypo routes to generate for validation
    const [endDate, setEndDate] = useState<Dayjs>(dayjs());
    // Is a validation request processing?
    const [isValidating, setIsValidating] = useState(false);
    // Should validation help show?
    const [showValidateHelp, setShowValidateHelp] = useState(false);

    // Ref for validation button, used so that the overlay/tooltip knows it's target.
    const validateButtonRef = useRef(null);

    // Updates the start/end dates when parent state changes to idle (data is saved).
    useEffect(() => {
        if (props.parentState === WindowState.IDLE) {
            dataStore.getTimetable(props.timetableId).then((timetable) => {
                const validity = timetable.validity;
                // If timetable hasn't started yet, use start of the timetable as start date of hypo generation.
                const _startDate = validity.from.isAfter(dayjs())
                    ? validity.from
                    : dayjs().startOf('day');
                // Add x amount of days to start date for route generation. If this would be after the end
                // of the timetable, use the end of the timetable.
                const _endDate =
                    validity.to && _startDate.add(addDays, 'days').isAfter(validity.to)
                        ? validity.to
                        : _startDate.add(addDays, 'days');

                setStartDate(_startDate);
                setEndDate(_endDate);
            });
        }
    }, [props.parentState]);

    const onValidate = async () => {
        if (props.parentState === WindowState.IDLE) {
            try {
                setIsValidating(true);
                const routes = await dataStore.hypoGenerateRoutes(
                    props.timetableId,
                    startDate,
                    endDate
                );
                if (routes.length) {
                    notificationStore.addNotification({
                        level: 'info',
                        text: t('notification.info.timetableValidated'),
                        autoConfirm: true,
                    });
                } else {
                    notificationStore.addNotification({
                        text: t('notification.warn.timetableValidation.noRoutes'),
                        level: 'warn',
                        autoConfirm: false,
                    });
                }
            } catch (e) {
                commonErrorHandler(e, {
                    status: {
                        400: (err) => {
                            if (err.response?.data.message === 'timetable_too_tight') {
                                notificationStore.addNotification({
                                    level: 'error',
                                    text: t('notification.error.timetableValidation.tooTight'),
                                    autoConfirm: false,
                                });
                                return true;
                            }

                            return false;
                        },
                    },
                });
            } finally {
                setIsValidating(false);
            }
        } else {
            notificationStore.addNotification({
                text: t('notification.warn.timetableValidation.saveBefore'),
                level: 'warn',
                autoConfirm: true,
            });
        }
    };

    return (
        <div>
            <InputGroup>
                <InputGroup.Prepend>
                    <Button
                        onClick={() => onValidate()}
                        variant={'success'}
                        onMouseEnter={() => setShowValidateHelp(true)}
                        onMouseLeave={() => setShowValidateHelp(false)}
                        ref={validateButtonRef}
                    >
                        {isValidating ? (
                            <Fa.FaSpinner className={'vinkaSpinner'} />
                        ) : (
                            t('button.validate')
                        )}
                    </Button>
                    <Overlay target={validateButtonRef} show={showValidateHelp} placement="right">
                        {(overlayProps) => (
                            <Tooltip
                                id={`tt-${props.timetableId}-validation-tooltip`}
                                {...overlayProps}
                            >
                                {t('tooltip.timetableValidation')}
                            </Tooltip>
                        )}
                    </Overlay>
                </InputGroup.Prepend>
                <VinkaDatePicker
                    pickerType={'range'}
                    defaultValue={[startDate, endDate]}
                    onChange={([start, end]) => {
                        if (start && end) {
                            setStartDate(start);
                            setEndDate(end);
                        }
                    }}
                />
            </InputGroup>
        </div>
    );
});
