import { LatLng } from 'leaflet';
import { makeAutoObservable, ObservableMap } from 'mobx';

import { Coordinates, MapEditor, MapItemType, Polygon } from '../types';
import RootStore from './RootStore';

export class MapStore {
    public editor: MapEditor = {
        state: 'inactive',
        callback: null,
        ownerId: null,
        initialPolygon: null,
    };
    // Note, map entity id's to array of [lat,lng] coordinates.
    // (Not [lng,lat], because this is easier for usage of Leaflet)
    public markers = new ObservableMap<string, Coordinates[]>();
    public polygons = new ObservableMap<string, Coordinates[][]>();
    // Map of frozen marker owners. Frozen owners cannot edit, add or remove markers.
    public frozenMarkerOwners = new ObservableMap<string, boolean>();
    public frozenPolygonOwners = new ObservableMap<string, boolean>();
    // Highlighted marker that appears on top of other markers. [lat, lng] coordinates.
    public highlightedMarker: Coordinates | null = null;
    public highlightedPolygon: Coordinates[] | null = null;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, { rootStore: false });
    }

    public startEditingMarker(ownerId: string, callback: (latLng: LatLng | null) => void): boolean {
        if (!this._preCheckMapEditing(ownerId, 'marker')) {
            return false;
        }

        this.editor.state = 'marker';
        this.editor.ownerId = ownerId;
        this.editor.callback = (data) => {
            if (data instanceof LatLng || data === null) {
                callback(data);
                this.disableEditing(ownerId);
            }
        };

        return true;
    }

    public startEditingPolygon(
        ownerId: string,
        initialPolygon: Coordinates[] | undefined,
        callback: (polygon: Polygon | null) => void
    ): boolean {
        if (!this._preCheckMapEditing(ownerId, 'polygon')) {
            return false;
        }

        this.editor.state = 'polygon';
        this.editor.ownerId = ownerId;
        this.editor.initialPolygon = initialPolygon || null;
        this.editor.callback = (data) => {
            if (data === null) {
                callback(data);
            } else if (Array.isArray(data)) {
                const polygon = data.map((latLng) => [latLng.lng, latLng.lat] as Coordinates);
                callback([polygon]);
            }
            this.disableEditing(ownerId);
        };

        return true;
    }

    public disableEditing(ownerId: string) {
        if (ownerId === this.editor.ownerId) {
            this.editor.state = 'inactive';
            this.editor.callback = null;
            this.editor.ownerId = null;
            this.editor.initialPolygon = null;
        }
    }

    public addMarker(ownerId: string, lng: number, lat: number) {
        if (this.frozenMarkerOwners.get(ownerId)) {
            return;
        }
        const existingMarkers = this.markers.get(ownerId) || [];
        this.markers.set(ownerId, [...existingMarkers, [lat, lng]]);
    }

    public addPolygon(ownerId: string, geoJsonPolygon: Polygon) {
        if (this.frozenPolygonOwners.get(ownerId)) {
            return;
        }

        const latLngPolygon = geoJsonPolygon[0].map(([lng, lat]) => [lat, lng] as Coordinates);
        const existingPolygons = this.polygons.get(ownerId) || [];
        this.polygons.set(ownerId, [...existingPolygons, latLngPolygon]);
    }

    public setHighlightedMarker(lngLatCoords: Coordinates | null) {
        this.highlightedMarker = lngLatCoords ? [lngLatCoords[1], lngLatCoords[0]] : null;
    }

    public setHighlightedPolygon(geoJsonPolygon: Polygon | null) {
        this.highlightedPolygon = geoJsonPolygon
            ? geoJsonPolygon[0].map(([lng, lat]) => [lat, lng] as Coordinates)
            : null;
    }

    /**
     * Clear markers, polygons by the owner. The components using MapStore are responsible of calling this.
     *
     * @param ownerId
     */
    public clearAllByOwner(ownerId: string) {
        if (!this.frozenMarkerOwners.get(ownerId)) {
            this.markers.delete(ownerId);
        }
        if (!this.frozenPolygonOwners.get(ownerId)) {
            this.polygons.delete(ownerId);
        }
    }

    /**
     * "Freezes" owner. Frozen owner cannot edit, add or remove item while frozen.
     * @param ownerId
     * @param itemType
     */
    public freezeOwner(ownerId: string, itemType: MapItemType) {
        switch (itemType) {
            case 'marker':
                this.frozenMarkerOwners.set(ownerId, true);
                break;
            case 'polygon':
                this.frozenPolygonOwners.set(ownerId, true);
                break;
            default:
                throw Error('Map item type not recognized: ' + itemType);
        }
    }

    public unfreezeOwner(ownerId: string, itemType: MapItemType) {
        switch (itemType) {
            case 'marker':
                this.frozenMarkerOwners.delete(ownerId);
                break;
            case 'polygon':
                this.frozenPolygonOwners.delete(ownerId);
                break;
            default:
                throw Error('Map item type not recognized: ' + itemType);
        }
    }

    public isOwnerFrozen(ownerId: string, itemType: MapItemType): boolean {
        switch (itemType) {
            case 'marker':
                return !!this.frozenMarkerOwners.get(ownerId);
            case 'polygon':
                return !!this.frozenPolygonOwners.get(ownerId);
            default:
                throw Error('Map item type not recognized: ' + itemType);
        }
    }

    public _preCheckMapEditing(ownerId: string, itemType: MapItemType): boolean {
        if (this.editor.state !== 'inactive') {
            this.rootStore.notificationStore.addNotification({
                level: 'error',
                text: 'notification.error.mapEditOccupied',
                autoConfirm: true,
                applyTranslation: true,
            });
            return false;
        }

        // Don't allow edit if owner is frozen
        if (itemType === 'marker' && this.frozenMarkerOwners.get(ownerId)) {
            this.rootStore.notificationStore.addNotification({
                level: 'warn',
                text: 'notification.warn.stopOwnerFrozen',
                autoConfirm: true,
                applyTranslation: true,
            });
            return false;
        } else if (itemType === 'polygon' && this.frozenPolygonOwners.get(ownerId)) {
            this.rootStore.notificationStore.addNotification({
                level: 'warn',
                text: 'notification.warn.areaOwnerFrozen',
                autoConfirm: true,
                applyTranslation: true,
            });
            return false;
        }

        this.rootStore.notificationStore.addNotification({
            level: 'info',
            text: 'notification.info.mapEditEnabled',
            autoConfirm: true,
            applyTranslation: true,
        });

        return true;
    }
}
