import { Icon, IconOptions } from 'leaflet';

import IconBlue from './img/marker-icon-2x-blue.png';
import IconGreen from './img/marker-icon-2x-green.png';
import IconRed from './img/marker-icon-2x-red.png';
import IconYellow from './img/marker-icon-2x-yellow.png';
import IconShadow from './img/marker-shadow.png';

const defaultOptions: IconOptions = {
    iconUrl: '',
    shadowUrl: IconShadow,
    iconAnchor: [12, 41],
    iconSize: [25, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
};

export const GreenMarker = new Icon({
    ...defaultOptions,
    iconUrl: IconGreen,
});

export const BlueMarker = new Icon({
    ...defaultOptions,
    iconUrl: IconBlue,
});

export const RedMarker = new Icon({
    ...defaultOptions,
    iconUrl: IconRed,
});

export const YellowMarker = new Icon({
    ...defaultOptions,
    iconUrl: IconYellow,
});
