import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import i18n from 'i18next';
import * as _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';

import { Auth0Provider } from '@auth0/auth0-react';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import 'leaflet/dist/leaflet.css';

import Layout from './components/Layout/Layout';
import QueryParamsUpdater from './consumers/QueryParamsUpdater';
import config from './infra/config';
import en from './locales/en';
import fi from './locales/fi';
import { ModalManager } from './providers/ModalManager';
import RootStoreProvider from './providers/RootStoreProvider';
import { WindowManager } from './providers/WindowManager';

const locale = localStorage.getItem('locale') || config.localization.locale;

i18n.use(initReactI18next).init({
    resources: { fi: { translation: fi }, en: { translation: en } },
    lng: locale,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false, // react already safes from xss
        format: (value, format) => {
            if (format === 'title' && typeof value === 'string') {
                return _.capitalize(value);
            }

            return value;
        },
    },
});

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(minMax);
dayjs.locale(locale);
dayjs.extend(localizedFormat);
dayjs.tz.setDefault(config.localization.timezone);

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain={config.auth0.domain}
            clientId={config.auth0.clientId}
            audience={config.auth0.audience}
            redirectUri={config.app.rootUrl}
        >
            <RootStoreProvider>
                <WindowManager>
                    <ModalManager>
                        <QueryParamsUpdater />
                        <Layout />
                    </ModalManager>
                </WindowManager>
            </RootStoreProvider>
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
